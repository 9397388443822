import { useState } from "react";
import NavBar from "./NavBar"
import { Link, Navigate, useSearchParams } from "react-router-dom";

function RecoverAccount() {
  const [newPass, setNewPass] = useState<string>('')
  const [newPassRepeat, setNewPassRepeat] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [resetSuccess, setResetSuccess] = useState<boolean | null>(null)

  const [searchParams, ] = useSearchParams();
  
  const jwt = localStorage.getItem('jwt')
  if (jwt != null){
    return (<Navigate to='/'></Navigate>)
  }

  async function onSubmit(){
    if (newPass == ''){
      setErrorMessage('Password cannot be blank')
      return
    }
    if (newPass != newPassRepeat){
      setErrorMessage('Passwords must match')
      return
    }
    const resp = await fetch('/api/auth/resetPasswordWithToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: searchParams.get('token'),
        user_id: searchParams.get('user_id'),
        newPassword: newPass,
      })
    })
    setResetSuccess(resp.ok)
  }

  return(
    <>
      <NavBar active=''/>
      <div className='content'>
      {resetSuccess == null && (
        <>
          <h2>Reset Password</h2>
          <label>New Password:</label>
          <input type='password' value={newPass} onChange={ev => setNewPass(ev.target.value)}/>
          <br />
          <label>Confirm New Password:</label>
          <input type='password' value={newPassRepeat} onChange={ev => setNewPassRepeat(ev.target.value)}/>
          <br />
          <input type='button' value='submit' onClick={onSubmit} />
          {errorMessage != null && (<p>{errorMessage}</p>)}
        </>
      )}
      {resetSuccess != null && resetSuccess && (
        <p>Password reset. You can now <Link to={'/Login'}>Login</Link></p>
      )}
      {resetSuccess != null && !resetSuccess && (
        <p>Password reset unsuccessful. The reset link may have expired. Click <Link to={'/ForgotPassword'}>here</Link> to try again</p>
      )}
      </div>
    </>
  )
}

export default RecoverAccount