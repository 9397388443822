import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './css/Dropdown.css'
import './css/DropdownMultiple.css'
import useWindowDimensions from '../utils/windowDimensions'

function DropdownMultiple(
  {name, options, values, onChange}: {name: string, options: string[], values: string[], onChange: (value: string[]) => void}
){
  const topRef = useRef<HTMLDivElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState<boolean>(false)

  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    function closeDropdown(e: Event): void {
      if(
        topRef.current && !topRef.current.contains(e.target as Node) 
        && optionsRef.current && !optionsRef.current.contains(e.target as Node)
      ){
        setExpanded(false)
      }
    }
    document.addEventListener('click', closeDropdown)
    return () => document.removeEventListener('click', closeDropdown)
  }, [])

  useLayoutEffect(() => {
    if (!optionsRef.current || !topRef.current || !optionsRef.current.offsetParent) return
    const offEdge = optionsRef.current.offsetLeft + optionsRef.current.offsetWidth >= optionsRef.current.offsetParent.clientWidth
    console.log(offEdge)
    if(topRef.current.offsetLeft + optionsRef.current.offsetWidth < optionsRef.current.offsetParent.clientWidth){
      optionsRef.current.style.left = `${topRef.current.offsetLeft}px`
      return
    }
    optionsRef.current.style.left = `${topRef.current.offsetLeft - optionsRef.current.offsetWidth + topRef.current.offsetWidth}px`
  }, [windowDimensions.width, expanded])

  return(
    <div style={{'textAlign': 'left'}}>
      <div ref = {topRef} className={`top-div ${values.length > 0 && 'top-div-selected'}`} onClick={() => setExpanded(!expanded)}>
        {`${name} ▾`}
      </div>
      {expanded && (
        <>
          <br/>
          <div ref={optionsRef} className='options-container'>
            {options.map(option => {
              const selected = values.includes(option)
              return (
                <div className={`option-div`} onClick={() => {onChange(
                  selected ? values.filter((item) => {return item != option}) : values.concat(option)
                )}}>
                  <div className={`checkbox ${selected && 'checkbox-selected'}`}/>
                  {option}
                </div>
              )
            })}
            <div className='bottom-buttons-container'>
              <div className='bottom-button' onClick={() => onChange([])}>Clear</div>
              <div className='bottom-button' onClick={() => onChange(options)}>All</div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default DropdownMultiple