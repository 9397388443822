import ReactSlider from 'react-slider'
import './css/TimeSlider.css'

function YearSlider({value, onChange}: {value: number, onChange: (value: number) => void}){
  return(
    <div className='slider-container'>
      <ReactSlider
        className="horizontal-slider"
        markClassName="mark"
        marks
        min={2020}
        max={2100}
        step={20}
        thumbClassName="thumb"
        trackClassName="track"
        renderThumb={(props) => <div {...props}><div className='thumb-pointer'>{value}</div></div>}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

export default YearSlider