import MapboxMap, { FillLayer, FullscreenControl, Layer, LineLayer, LngLatBoundsLike, MapLayerMouseEvent, MapRef, NavigationControl, SkyLayer, Source, useControl } from 'react-map-gl';
import NavBar from "./NavBar"
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FeatureCollection } from 'geojson';
import './css/Map.css'
import './css/ModelHex.css'
import {ArcLayer, GeoJsonLayer} from '@deck.gl/layers/typed';
import {H3HexagonLayer} from '@deck.gl/geo-layers/typed';
import {MapboxOverlay, MapboxOverlayProps} from '@deck.gl/mapbox/typed';
import {cellToLatLng} from 'h3-js';
import Dropdown from './components/Dropdown';
import DropdownMultiple from './components/DropdownMultiple';
import Sunburst, { SunburstDataPoint } from './components/Sunburst';
import {createHueMapFromPieData, hexStringToRGBArray, hslToHex} from './utils/ColorUtils'
import { motion } from 'framer-motion';

function DeckGLOverlay(props: MapboxOverlayProps & {
  interleaved?: boolean;
}) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

export function ModelHex3Map() {

  const mapRef = useRef<MapRef>(null);

  type Location = {
    lng: number
    lat: number
    zoom: number
    bounds: LngLatBoundsLike
  }

  const kelowna: Location = useMemo(() => { 
    return{
      lng: -119.5333,
      lat: 49.9313,
      zoom: 9.5,
      bounds: [[-120.75, 49.5], [-118.25, 50.4]],
    }
  }, [])
  
  const vancouver: Location = useMemo(() => {
    return {
      lng: -122.9307,
      lat: 49.1910,
      zoom: 9.82,
      bounds: [[-124, 48.5], [-121.75, 49.7]],
    }
  }, [])

  const [currentLocation, setCurrentLocation] = useState<Location>(kelowna)
  const [lng, setLng] = useState(currentLocation.lng);
  const [lat, setLat] = useState(currentLocation.lat);
  const [zoom, setZoom] = useState(currentLocation.zoom);
  const [pitch, setPitch] = useState(40)
  const [maxBounds, setMaxBounds] = useState<LngLatBoundsLike | undefined>(undefined);
  const [mapFlying, setMapFlying] = useState(false)

  const [locationDropdownValue, setLocationDropdownValue] = useState<string | null>('Kelowna')
  const [incomeFilter, setIncomeFilter] = useState<string[]>([])
  const [ageFilter, setAgeFilter] = useState<string[]>([])
  const [educationFilter, setEducationFilter] = useState<string[]>([])
  const [sexFilter, setSexFilter] = useState<string[]>([])

  const [selectedHex, setSelectedHex] = useState<string | null>(null)
  const [hoveredHex, setHoveredHex] = useState<string | null>(null)
  const [currentRoot, setCurrentRoot] = useState<SunburstDataPoint | null>(null)
  const [rootDepth, setRootDepth] = useState<number>(0)
  const [externalNewRoot, setExternalNewRoot] = useState<{data: SunburstDataPoint, index: number}| null>(null)
  const [currentLayer1Data, setCurrentLayer1Data] = useState<SunburstDataPoint[]>([])

  const [firstHexSelected, setFirstHexSelected] = useState<boolean>(false)

  const [hexes, setHexes] = useState<FeatureCollection | null>(null)
  const [selectedHexHierarchyData, setSelectedHexHierarchyData] = useState<SunburstDataPoint | null>(null)

  useMemo(() => {
    fetch('api/geoDatabase/getAllStartHexes?' + new URLSearchParams({
        zoom: '7',
      }))
      .then(resp => resp.json())
      .then(json => {setHexes(json); console.log(json); return json})
      .catch(err => console.error('Could not load data', err));
  }, []);

  useMemo(() => {
    function convertTripDataToHierarchy(tripData: {start_hex: string, end_hex: string, trip_number: number, person: number}[] | null): SunburstDataPoint | null {
      if(tripData == null || selectedHex == null) return null;
      function findChildTrips(tripData: {start_hex: string, end_hex: string, trip_number: number, person: number}[], parentHex: string, parentTripNumber: number, parentTripPeople: number[]): SunburstDataPoint[] {
        const ungroupedChildTrips = tripData.filter(trip => trip.trip_number == parentTripNumber + 1 && trip.start_hex == parentHex && parentTripPeople.includes(trip.person))
        const groupedChildTrips = Object.groupBy(ungroupedChildTrips, (trip) => trip.end_hex)
        const childTrips = Object.keys(groupedChildTrips).flatMap(hex => {
          const currentHexChildTrips = groupedChildTrips[hex]
          if (currentHexChildTrips == null) return [];
          return {title: hex, value: currentHexChildTrips.length, children: findChildTrips(tripData, hex, parentTripNumber + 1, currentHexChildTrips.map(trip => trip.person).filter((value, index, array) => array.indexOf(value) === index))}
        })
        return childTrips;
      }
      const allTripPeople = tripData.map(trip => trip.person).filter((value, index, array) => array.indexOf(value) === index)
      const childTrips = findChildTrips(tripData, selectedHex, 0, allTripPeople)
      return {title: selectedHex, value: allTripPeople.length, children: childTrips}
    }
    if (selectedHex == null) return
    setFirstHexSelected(true);
    fetch('api/geoDatabase/getModelMultiTripsFromHexFilteredUnGrouped?' + new URLSearchParams({
      hex: selectedHex,
      zoom: '7',
      ...(incomeFilter.length && {income: incomeFilter.toString()}),
      ...(educationFilter.length && {education: educationFilter.toString()}),
      ...(sexFilter.length && {sex: sexFilter.toString()}),
    }))
    .then(resp => resp.ok 
        ? resp.json() as unknown as {start_hex: string, end_hex: string, trip_number: string, person: string}[] 
        : Promise.reject(resp)
    )
    .then(json => json.map(row => {return {...row, trip_number: parseInt(row.trip_number), person: parseInt(row.person)}}))
    .then(tripData => setSelectedHexHierarchyData(convertTripDataToHierarchy(tripData)))
    .catch(err => console.error('Could not load data', err));
  }, [educationFilter, incomeFilter, selectedHex, sexFilter])

  useMemo(() => {
    setCurrentRoot(selectedHexHierarchyData)
  }, [selectedHexHierarchyData])

  useMemo(() => {
    if(!mapFlying){
      if(locationDropdownValue == 'Kelowna' && currentLocation != kelowna){
        setCurrentLocation(kelowna)
      }
      if(locationDropdownValue == 'Vancouver' && currentLocation != vancouver){
        setCurrentLocation(vancouver)
      }
    }
  }, [currentLocation, kelowna, locationDropdownValue, mapFlying, vancouver])

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': zoom
    }
  };

  const outlineLayer: LineLayer = {
    id: 'outlineLayer',
    type: 'line',
    paint: {
      'line-color': '#000000',
      'line-width': [
        'interpolate', 
        ['linear'], 
        ["zoom"],
        9, 0.3, 
        12, 2,
      ],
    }
  }  

  const hexDataLayer: FillLayer = useMemo(() =>{
    const layer: FillLayer = {
      id: 'hexDataLayer',
      type: 'fill',
      paint: {
        'fill-color': ['rgb', 200, 200, 200],
        'fill-opacity': 0.1
      }
    }
    return layer
  }, [])

  const hexLayer = useMemo(() => {
    console.log(currentLayer1Data)
    const hueMap = createHueMapFromPieData(currentLayer1Data.map(dataPoint => dataPoint.value))
    return new H3HexagonLayer({
      id: 'hexLayer',
      data: currentRoot == null ? [] : currentLayer1Data.flatMap((dataPoint, index) => {
        if (dataPoint.title == 'done') return []
        return {
          dataPoint: dataPoint,
          color: dataPoint.title == currentRoot.title 
          ? [212, 212, 212, 150] 
          : hexStringToRGBArray(hslToHex(hueMap[index], 60, hoveredHex == dataPoint.title ? 40 : 65)).concat(hoveredHex == dataPoint.title ? 200 : 150),
          index: index
        }
      }),
      pickable: true,
      wireframe: false,
      filled: true,
      extruded: false,
      getHexagon: d => d.dataPoint.title,
      getFillColor: d => d.color,
      onClick: (d) => {
        setExternalNewRoot({data: d.object.dataPoint, index: d.object.index})
      },
      onHover: d => {
        if (d.object){
          setHoveredHex(d.object.dataPoint.title)
        } else {
          setHoveredHex(null)
        }
      },
    })
    // don't want to update right away when selected hex changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLayer1Data, hoveredHex])

  const arcLayer = useMemo(() => {
    const hueMap = createHueMapFromPieData(currentLayer1Data.map(dataPoint => dataPoint.value))
    return new ArcLayer({
      id: 'arcLayer',
      data: currentRoot == null ? [] : currentLayer1Data.flatMap((dataPoint, index) => {
        if (dataPoint.title == 'done') return []
        const startLatLng = cellToLatLng(currentRoot.title)
        const endLatLng = cellToLatLng(dataPoint.title)
        return {
          source: [
            startLatLng[1], 
            startLatLng[0], 
            0
          ],
          target: [
            endLatLng[1], 
            endLatLng[0],
            0
          ],
          color: hexStringToRGBArray(hslToHex(hueMap[index], 60, hoveredHex == dataPoint.title ? 40 : 65)).concat(hoveredHex == dataPoint.title ? 200 : 150),
          count: dataPoint.value,
        }
      }),
      getSourcePosition: d => d.source,
      getTargetPosition: d => d.target,
      getSourceColor: d => d.color,
      getTargetColor: d => d.color,
      getWidth: d => Math.min(Math.max(2, d.count/20), 50)
    })
    // don't want to update right away when selected hex changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLayer1Data, hoveredHex]);

  const selectedOutlineLayer = useMemo(() => {
    console.log(selectedHex)
    return new GeoJsonLayer({
      id: 'selectedOutlineLayer',
      data: hexes && selectedHex != null ? hexes.features.filter(feature => feature.properties?.hex == selectedHex) : [],
      stroked: true,
      filled: false,
      getLineWidth: 200,
    })
  }, [hexes, selectedHex]);

  
  useEffect(() => {
    if(mapRef.current == undefined){return}
    setSelectedHex(null)
    setMaxBounds(undefined)
    setMapFlying(true)
    mapRef.current?.flyTo({
      center: {
        lng: currentLocation.lng, 
        lat: currentLocation.lat
      }, 
      zoom: currentLocation.zoom,
      pitch: 0,
      bearing: 0
    })
  }, [currentLocation])

  function closeChart(){
    setSelectedHex(null);
    setHoveredHex(null);
    setCurrentRoot(null);
    setRootDepth(0);
    setExternalNewRoot(null);
    setSelectedHexHierarchyData(null)
  }

  function onMapMoveEnd(){
    if(mapFlying){
      setMaxBounds(currentLocation.bounds)
      setMapFlying(false)
      // mapRef.current?.easeTo({
      //   pitch: 0,
      //   bearing: 0
      // })
    }
  }

  function onMapClick(e: MapLayerMouseEvent){
    if (!e.features || e.features?.length == 0){
      setSelectedHex(null)
      return
    }
    if(selectedHex == null){
      setSelectedHex(e.features[0].properties?.hex)
    }
  }

  const onMapLoad = useCallback(() => {
    // mapRef.current?.getMap().addSource('mapbox-dem', {
    //   'type': 'raster-dem',
    //   'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
    //   'tileSize': 512,
    // });
    // mapRef.current?.getMap().setTerrain({
    //   'source': 'mapbox-dem',
    // });
    mapRef.current?.on('move', () => {
      if(mapRef.current){
        setLng(mapRef.current.getCenter().lng);
        setLat(mapRef.current.getCenter().lat);
        setZoom(mapRef.current.getZoom());
        setPitch(mapRef.current.getPitch())
      }
    });
  }, []);

  return (
    <div className='map-container' style={{pointerEvents: mapFlying ? 'none' : 'auto'}}>
      <MapboxMap
        id='map'
        ref={mapRef}
        interactive={true}
        interactiveLayerIds={['hexDataLayer']}
        onClick={onMapClick}
        onLoad={onMapLoad}
        onMoveEnd={onMapMoveEnd}
        mapboxAccessToken="pk.eyJ1Ijoic3R1bWNnIiwiYSI6ImNrcDRtdHE1ZjBiYTkyeHQ4NG5sc3VpM2MifQ.N_cSsEXlTBk7q92Xdpfbug"
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoom,
          pitch: pitch,
        }}
        maxBounds={maxBounds}
        mapStyle="mapbox://styles/stumcg/clwl4zrxd00j801rb65kc9ui7"
      >
        <Layer {...skyLayer} />
        {
          hexes != null && hexDataLayer != null && (
            <Source type="geojson" data={hexes}>
              <Layer {...hexDataLayer}/>
              <Layer {...outlineLayer}/>
            </Source>
          )
        }
        <DeckGLOverlay interleaved={true} layers={[hexLayer, arcLayer, selectedOutlineLayer]} />
        <NavigationControl position='bottom-left' visualizePitch={true}/>
        <FullscreenControl position='bottom-left'/>
        <div className='map-controls map-controls-grid-parent'>
          {!firstHexSelected && (
            <h1 style={{position: 'absolute', top: '30%', width: '100%', textAlign: 'center'}}>
              Select a hex to explore daily trips originating from that location
            </h1>
          )}
          <div className='filters-container'>
            <div className='dropdown-container'>
              <Dropdown
                name={'Location'}
                options={['Kelowna', 'Vancouver']}
                value={locationDropdownValue}
                onChange={(value) => setLocationDropdownValue(value)}
                anyOption={false}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Income'} 
                options={['1', '2', '3', '4']}
                values={incomeFilter}
                onChange={(values) => setIncomeFilter(values)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Age'} 
                options={['17-25', '25-45', '45-65', '65+']}
                values={ageFilter}
                onChange={(values) => setAgeFilter(values)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Education'} 
                options={['1', '2', '3', '4']}
                values={educationFilter}
                onChange={(values) => setEducationFilter(values)}
              />
            </div>
            <div className='dropdown-container'>
              <DropdownMultiple
                name={'Sex'} 
                options={['1', '2']}
                values={sexFilter}
                onChange={(values) => setSexFilter(values)}
              />
            </div>
          </div>
          {selectedHex && selectedHexHierarchyData && currentRoot && (
            <div className='right-sidebar'>
              <div onClick={() => {closeChart()}} style={{'float': 'left', 'cursor': 'pointer', fontSize: '20'}}>&#x2715;</div>
              <div style={{margin: '20px'}}>
                <h1>{rootDepth + 1}{(rootDepth + 1) % 10 == 1 ? 'st' : ((rootDepth + 1) % 10 == 2 ? 'nd' : ((rootDepth + 1) % 10 == 3 ? 'rd' : 'th'))} trip of the day:</h1>
                <Sunburst
                  root= {selectedHexHierarchyData}
                  radius={160}
                  layersNum={2}
                  margin={2}
                  currentRoot={currentRoot}
                  setCurrentRoot={setCurrentRoot}
                  rootDepth={rootDepth}
                  setRootDepth={setRootDepth}
                  hoveredHex={hoveredHex}
                  setHoveredHex={setHoveredHex}
                  externalNewRoot={externalNewRoot}
                  setCurrentLayer1Data={setCurrentLayer1Data}
                />
              </div>
              <div style={{width: '320px', margin: '20px'}}>
                <p>Click a section of the pie or a hex on the map to expolore additional chaine trips. Click the center of the pie to go back</p>                    
                <div style={{width: '2em', height: '2em', backgroundColor: '#e4e4e4', display:'inline-block'}}/>
                <div style={{lineHeight: '2em', display:'inline-block', verticalAlign: 'top', marginLeft: '1em'}}> Trips within currently selected hex</div>
                <br/>
                <div style={{width: '2em', height: '2em', backgroundColor: '#4e4e4e', display:'inline-block'}}/>
                <div style={{lineHeight: '2em', display:'inline-block', verticalAlign: 'top', marginLeft: '1em'}}> No more trips for the day</div>
                <p>Currently displayed trips include {currentRoot.value} of original {selectedHexHierarchyData.value} people</p>
                <svg width={320} height={20}>
                  <defs>
                    <clipPath id="clip-bar">
                      <motion.rect 
                        x={0} 
                        height={20}
                        initial={{
                          width: 240 * (currentRoot.value / selectedHexHierarchyData.value)
                        }}
                        animate={{
                          width: 240 * (currentRoot.value / selectedHexHierarchyData.value)
                        }}
                        transition={{
                          duration: 1
                        }}
                      />
                    </clipPath>
                  </defs>
                  <rect width={240} height={20} rx={10} stroke={'black'} fillOpacity={0}/>
                  <rect width={240} height={20} rx={10} fill='black' stroke={'black'} clipPath='url(#clip-bar)'/>
                  <text x={250} y={10} fill={'black'} fontSize={16} dominant-baseline={'central'}>
                    {
                      currentRoot.value / selectedHexHierarchyData.value < 0.1
                      ? Math.round((currentRoot.value / selectedHexHierarchyData.value) * 1000) / 10
                      : Math.round((currentRoot.value / selectedHexHierarchyData.value) * 100)
                    }%
                  </text>
                </svg>
              </div>
            </div>
          )}
        </div>
      </MapboxMap>
    </div>
  )
}

function ModelHex3(){
  return(
    <>
      <NavBar active='ModelHex3'/>
      <div className='content-fullscreen'>
        <ModelHex3Map/>
      </div>
    </>
  )
}

export default ModelHex3
