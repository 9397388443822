import { callAPI } from "./Api";
import NavBar from "./NavBar";
import { useEffect, useState } from "react";

function Data(){
  const [userData, setUserData] = useState('')
  const [memberData, setMemberData] = useState('')
  const jwt = localStorage.getItem('jwt')
  useEffect(() => {
    callAPI('/api/testUserData', 'GET')
      .then((res) => res.text())
      .then(setUserData)

    callAPI('/api/testMemberData', 'GET')
      .then((res) => res.text())
      .then(setMemberData);
  }, [jwt])
  
  return(
    <>
      <NavBar active='Data'/>
      <h1>User Data</h1>
      <p>{userData}</p>
      <h1>Member Data</h1>
      <p>{memberData}</p>
    </>
    )
}

export default Data