export const tab20Colors = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
]
//https://eleanormaclure.wordpress.com/wp-content/uploads/2011/03/colour-coding.pdf
export const kellyColorsHex = [
  '#FFB300', // Vivid Yellow
  '#803E75', // Strong Purple
  '#FF6800', // Vivid Orange
  '#A6BDD7', // Very Light Blue
  '#C10020', // Vivid Red
  '#CEA262', // Grayish Yellow
  '#817066', // Medium Gray

  // The following don't work well for people with defective color vision
  '#007D34', // Vivid Green
  '#F6768E', // Strong Purplish Pink
  '#00538A', // Strong Blue
  '#FF7A5C', // Strong Yellowish Pink
  '#53377A', // Strong Violet
  '#FF8E00', // Vivid Orange Yellow
  '#B32851', // Strong Purplish Red
  '#F4C800', // Vivid Greenish Yellow
  '#7F180D', // Strong Reddish Brown
  '#93AA00', // Vivid Yellowish Green
  '#593315', // Deep Yellowish Brown
  '#F13A13', // Vivid Reddish Orange
  '#232C16', // Dark Olive Green
  ]


export function getTab20ByIndex({index, secondary = false}: {index: number, secondary?: boolean}): string{
  return tab20Colors[((index < 10 ? index : (index % 10) + Math.floor(index / 10)) * 2) + (secondary ? 1 : 0)];
}

export function getTab20SecondaryFromPrimary(color: string): string{
  return tab20Colors[tab20Colors.indexOf(color) + 1]
}

export function hexStringToRGBArray(hex: string): number[]{
  const rgb = [0, 0, 0]
  rgb[0] = parseInt(hex.slice(1, 3), 16)
  rgb[1] = parseInt(hex.slice(3, 5), 16)
  rgb[2] = parseInt(hex.slice(5, 7), 16)
  return rgb
}

export function hslToHex(h: number, s: number, l: number): string{
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
} 

// export function getFullHueColorMapColor(index: number, divisions: number, saturation: number, brightnes: number): string{
//   const hue = Math.round((110 / divisions) * index) + 60
//   return hslToHex(hue, saturation, brightnes);
// }

export function getFullHueColorMapColor(index: number, divisions: number, saturation: number, brightnes: number): string{
  const hue = Math.round((360 / divisions) * index)
  return hslToHex(hue, saturation, brightnes);
}

export function createHueMapFromPieData(data: number[]): number[]{
  if(data.length == 0) return []
  const colorMap = new Array(data.length).fill(0)
  const sortedData = data.map((value, index) => ({value: value, index: index})).sort((a, b) => b.value - a.value)
  // const dataTotal = data.reduce((a, b) => a + b)
  // let runningTotal = 0
  for(let i = 0; i < sortedData.length; i++){
    // colorMap[sortedData[i].index] = (((runningTotal + (sortedData[i].value / 2)) / dataTotal) * 320) - 40
    colorMap[sortedData[i].index] = (i/sortedData.length * 360)
    // runningTotal += sortedData[i].value
  }
  return colorMap
}