import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import MapboxMap, { FillLayer, FillExtrusionLayer, FullscreenControl, Layer, NavigationControl, Source } from 'react-map-gl';
import type { MapLayerMouseEvent, MapRef, SkyLayer } from 'react-map-gl';
import { FeatureCollection, Feature } from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import './css/Map.css'

function ExtrudedMap() {
  type Location = {
    lng: number
    lat: number
    zoom: number
  }

  const kelowna: Location = {
    lng: -119.4966,
    lat: 49.8863,
    zoom: 11
  }
  
  const vancouver: Location = {
    lng: -123.0278,
    lat: 49.2370,
    zoom: 10
  }

  const mapRef = useRef<MapRef>(null);

  const [lng, setLng] = useState(kelowna.lng);
  const [lat, setLat] = useState(kelowna.lat);
  const [zoom, setZoom] = useState(kelowna.zoom);

  const [kelownaGeoData, setKelownaGeoData] = useState<null | FeatureCollection>(null);
  const [kelownaDAs, setKelownaDAs] = useState<null | {layer: FillExtrusionLayer, source: Feature}[]>(null)
  const [vancouverGeoData, setVancouverGeoData] = useState<null | FeatureCollection>(null);
  const [vancouverDAs, setVancouverDAs] = useState<null | FillExtrusionLayer>(null)
  const [selectedFeature, setSelectedFeature] = useState<null | Feature>(null)

  const [value, setValue] = useState('value1')

  const kelownaFlatLayer: FillLayer = {
    id: 'geoLayer',
    type: 'fill',
    paint: {
      'fill-color': '#4E3FC8',
      'fill-opacity': 0.5
    }
  };

  useEffect(() => {
    fetch('api/geoDatabase/okda')
      .then(resp => resp.json())
      .then(json => {setKelownaGeoData(json); console.log(json); return json})
      .catch(err => console.error('Could not load data', err));
    fetch('api/geoDatabase/vanda')
      .then(resp => resp.json())
      .then(json => {setVancouverGeoData(json); console.log(json); return json})
      .catch(err => console.error('Could not load data', err));
  }, []);

  const csdColorsRGB = new Map()
  csdColorsRGB.set("Duck Lake 7", "#d7191c")
  csdColorsRGB.set("Kelowna", "#e75437")
  csdColorsRGB.set("Lake Country", "#f69053")
  csdColorsRGB.set("North Okanagan C", "#febe74")
  csdColorsRGB.set("Peachland", "#ffdf9a")
  csdColorsRGB.set("Priest's Valley 6", "#ffffbf")
  csdColorsRGB.set("Tsinstikeptum  9", "#def2b4")
  csdColorsRGB.set("Tsinstikeptum 10", "#bce4aa")
  csdColorsRGB.set("Vernon", "#91cba9")
  csdColorsRGB.set("West Kelowna", "#5ea7b1")
  csdColorsRGB.set("", "#2b83ba")

  const csdColorsHue = new Map()
  Array.from(csdColorsRGB.keys()).forEach((element, i) => {
    csdColorsHue.set(element, (i/11) * 360)
  });

  useMemo(() => {
    if(kelownaGeoData == null){return}
    setKelownaDAs(
      kelownaGeoData.features.map((feature: Feature) => {
        const layer: FillExtrusionLayer = {
          id: 'kelownaDaLayer' + feature.id,
          type: 'fill-extrusion',
          paint: {
            'fill-extrusion-color': `hsl(${csdColorsHue.get(feature.properties?.['csdname'])}, ${feature.properties?.[value] * 100}%, 70%)`,
            'fill-extrusion-color-transition': {delay: 0, duration: 500},
            "fill-extrusion-opacity": 0.9,
            'fill-extrusion-height': feature.properties?.[value] * 1000,
            'fill-extrusion-height-transition': {delay: 0, duration: 2000}
          }
        }
        if (feature.id == selectedFeature?.id && layer.paint != null){
          layer.paint['fill-extrusion-opacity'] = 1
          layer.paint['fill-extrusion-color'] = `hsl(${csdColorsHue.get(feature.properties?.['csdname'])}, 100%, 50%)`
        }
        const source: Feature = feature
        return {layer: layer, source: source}
      })
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kelownaGeoData, value])

  useMemo(() => {
    if(vancouverGeoData == null){return}
    setVancouverDAs({
      id: 'vancouverDaLayer' + ['get', 'id'],
      type: 'fill-extrusion',
      paint: {
        //@ts-expect-error hsl missing from expression type def
        'fill-extrusion-color': ['hsl', 0, ['*', 100, ['get', value]], 70],
        'fill-extrusion-color-transition': {delay: 0, duration: 500},
        "fill-extrusion-opacity": 0.9,
        'fill-extrusion-height': ['*', 10000, ['get', value]],
        'fill-extrusion-height-transition': {delay: 0, duration: 2000}
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vancouverGeoData, value])

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': 15
    }
  };

  const onMapLoad = useCallback(() => {
    // mapRef.current?.getMap().addSource('mapbox-dem', {
    //   'type': 'raster-dem',
    //   'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
    //   'tileSize': 512,
    //   'maxzoom': 14
    // });
    // mapRef.current?.getMap().setTerrain({
    //   'source': 'mapbox-dem',
    //   'exaggeration': 1.5
    // });
    mapRef.current?.on('move', () => {
      if(mapRef.current){
        setLng(mapRef.current.getCenter().lng);
        setLat(mapRef.current.getCenter().lat);
        setZoom(mapRef.current.getZoom());
      }
    });
  }, []);

  function onMapClick(e: MapLayerMouseEvent){
    console.log(e)
    console.log(e.features)
    if(selectedFeature != null){
      mapRef.current?.getMap().setPaintProperty(`kelownaDaLayer${selectedFeature.id}`, 'fill-extrusion-opacity', 0.9)
      mapRef.current?.getMap().setPaintProperty(`kelownaDaLayer${selectedFeature.id}`, 'fill-extrusion-color', `hsl(${csdColorsHue.get(selectedFeature.properties?.['csdname'])}, ${(selectedFeature.properties?.[value] / 10) + 30}%, 70%)`)
    }
    if (e.features == null || e.features?.length == 0){
      setSelectedFeature(null)
    }
    else{
      const feature = e.features[0]
      setSelectedFeature(feature)
      mapRef.current?.getMap().setPaintProperty(`kelownaDaLayer${feature.id}`, 'fill-extrusion-opacity', 1)
      mapRef.current?.getMap().setPaintProperty(`kelownaDaLayer${feature.id}`, 'fill-extrusion-color', `hsl(${csdColorsHue.get(feature.properties?.['csdname'])}, 100%, 50%)`)
    }
  }

  function flyTo(location: Location){
    mapRef.current?.flyTo({
      center: {
        lng: location.lng, 
        lat: location.lat
      }, 
      zoom: location.zoom
    })
  }

  return <div className='map-container'>
  <MapboxMap
    id='map'
    ref = {mapRef}
    interactive={true}
    interactiveLayerIds={kelownaDAs?.map(area => {return area.layer.id})}
    onLoad={onMapLoad}
    onClick={onMapClick}
    mapboxAccessToken="pk.eyJ1Ijoic3R1bWNnIiwiYSI6ImNrcDRtdHE1ZjBiYTkyeHQ4NG5sc3VpM2MifQ.N_cSsEXlTBk7q92Xdpfbug"
    initialViewState={{
      longitude: lng,
      latitude: lat,
      zoom: zoom
    }}
    mapStyle="mapbox://styles/mapbox/streets-v9"
  >
    <Layer {...skyLayer} />
    <NavigationControl position='bottom-right' visualizePitch={true}/>
    <FullscreenControl position='bottom-right'/>
    <div className='map-controls'>
      <div className='map-info'>
        Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom: {zoom.toFixed(2)}
      </div>
      {selectedFeature != null && (
        <div className='selection-info'>
          <h3>ID: {selectedFeature.id}</h3>
          <p>Region: {selectedFeature.properties?.['csdname']}</p>
          <p>Value 1: {selectedFeature.properties?.['value1']}</p>
          <p>Value 2: {selectedFeature.properties?.['value2']}</p>
          <p>Value 3: {selectedFeature.properties?.['value3']}</p>
        </div>
      )}
      <br/>
      <div className="map-button" onClick={() => flyTo(kelowna)}>Kelowna</div>
      <div className="map-button" onClick={() => flyTo(vancouver)}>Vancouver</div>
      <br/>
      <div className="map-button" onClick={() => setValue('value1')}>Value1</div>
      <br/>
      <div className="map-button" onClick={() => setValue('value2')}>Value2</div>
      <br/>
      <div className="map-button" onClick={() => setValue('value3')}>Value3</div>
    </div>
    {
      kelownaGeoData != null && (
        <>
          <Source type="geojson" data={kelownaGeoData}>
            <Layer {...{...{layout: {visibility: 'none'}}, ...kelownaFlatLayer}}/>
          </Source>
        </>
      )
    }
    {
      kelownaDAs != null && kelownaDAs.map((area) => {
        return(
          <Source type="geojson" data={area.source}>
            <Layer {...{...{layout: {visibility: 'visible'}}, ...area.layer}}/>
          </Source>
        )
      })
    }
    {
      vancouverDAs != null && vancouverGeoData != null &&(
        <Source type="geojson" data={vancouverGeoData}>
          <Layer {...{...{layout: {visibility: 'visible'}}, ...vancouverDAs}}/>
        </Source>
      )
    }
  </MapboxMap>
</div>
}
export default ExtrudedMap