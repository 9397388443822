import { Link } from 'react-router-dom'
import NavBar from './NavBar.tsx'

function OldMaps(){
  return (
    <>
      <NavBar active='OldMaps'/>
      <div className='content'>
        <Link to="/Map">First Maps</Link>
        <br />
        <Link to="/Census">Census Maps</Link>
      </div>
    </>
  )
}

export default OldMaps