import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import MapboxMap, { FillLayer, FullscreenControl, Layer, NavigationControl, Source } from 'react-map-gl';
import type { LineLayer, LngLatBoundsLike, MapLayerMouseEvent, MapRef, SkyLayer } from 'react-map-gl';
import { FeatureCollection, Feature, GeoJsonProperties } from 'geojson';
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import 'mapbox-gl/dist/mapbox-gl.css';
import './css/Map.css'
import Graph from './Graph';

function BarPlotMap() {

  const mapRef = useRef<MapRef>(null);

  const [kelownaGeoData, setKelownaGeoData] = useState<null | FeatureCollection>(null);
  const [vancouverGeoData, setVancouverGeoData] = useState<null | FeatureCollection>(null);
  const [barPlotData, setBarPlotData] = useState<null | GeoJsonProperties[]>(null)
  const [selectedFeature, setSelectedFeature] = useState<null | Feature>(null)
  const [value, setValue] = useState('value1')

  type Location = {
    lng: number
    lat: number
    zoom: number
    bounds: LngLatBoundsLike
  }

  const kelowna: Location = useMemo(() => { 
    return{
      lng: -119.5333,
      lat: 49.9313,
      zoom: 9.08,
      bounds: [[-120.75, 49.5], [-118.25, 50.4]],
    }
  }, [])
  
  const vancouver: Location = useMemo(() => {
    return {
      lng: -122.9307,
      lat: 49.1910,
      zoom: 9.82,
      bounds: [[-124, 48.5], [-121.75, 49.7]],
    }
  }, [])

  const [currentLocation, setCurrentLocation] = useState<Location>(kelowna)
  const [lng, setLng] = useState(currentLocation.lng);
  const [lat, setLat] = useState(currentLocation.lat);
  const [zoom, setZoom] = useState(currentLocation.zoom);
  const [maxBounds, setMaxBounds] = useState<LngLatBoundsLike | undefined>(currentLocation.bounds);
  const [mapFlying, setMapFlying] = useState(false)

  useEffect(() => {
    fetch('api/geoDatabase/okda')
      .then(resp => resp.json())
      .then(json => {setKelownaGeoData(json); console.log(json); return json})
      .catch(err => console.error('Could not load data', err));
    fetch('api/geoDatabase/vanda')
      .then(resp => resp.json())
      .then(json => {setVancouverGeoData(json); console.log(json); return json})
      .catch(err => console.error('Could not load data', err));
  }, []);

  useMemo(() => {
    let currentLocationData = null
    if(currentLocation == kelowna){
      currentLocationData = kelownaGeoData
    }else if(currentLocation == vancouver){
      currentLocationData = vancouverGeoData
    }
    if(currentLocationData == null){
      setBarPlotData(null);
      return;
    }
    setBarPlotData(currentLocationData.features.map((feature) => {
        const properties: GeoJsonProperties = feature.properties;
        if (properties == null) {return null}
        properties.id = feature.id;
        return feature.properties
      }))
  }, [currentLocation, kelowna, kelownaGeoData, vancouver, vancouverGeoData])

    const vancouverDALayer: FillLayer = useMemo(() =>{
      const selectedFeatureId = selectedFeature == null ? null : selectedFeature.id;
      const layer: FillLayer = {
        id: 'vancouverDaLayer',
        type: 'fill',
        paint: {
          'fill-color': [
            // @ts-expect-error hsl expression missing from mapbox type definitions
            'hsl', 
            [
              'interpolate', 
              ['linear'], 
              ['get', value],
              0, 50,
              1, 0,
            ],
            [
              'case',
              ['==', ['id'], selectedFeatureId],
              100,
              70,
            ], 
            [
              'interpolate', 
              ['linear'], 
              ['get', value],
              0, 70,
              1, 50,
            ],
          ],
          'fill-color-transition': {delay: 0, duration: 0},
          'fill-opacity': [
            'case',
            ['==', ['id'], selectedFeatureId],
            0.85,
            0.7,
          ], 
        }
      }
      return layer
    }, [value, selectedFeature])

  const vancouverDaOutlineLayer: LineLayer = {
    id: 'vancouverDaOutlineLayer' + ['get', 'id'],
    type: 'line',
    paint: {
      'line-color': '#000000',
      'line-width': [
        'interpolate', 
        ['linear'], 
        ["zoom"],
        9, 0.3, 
        12, 2,
      ],
    }
  }

  const kelownaDALayer: FillLayer = useMemo(() =>{
    const selectedFeatureId = selectedFeature == null ? null : selectedFeature.id;
    const layer: FillLayer = {
      id: 'kelownaDaLayer',
      type: 'fill',
      paint: {
        'fill-color': [
          // @ts-expect-error hsl expression missing from mapbox type definitions
          'hsl', 
          [
            'interpolate', 
            ['linear'], 
            ['get', value],
            0, 50,
            1, 0,
          ],
          [
            'case',
            ['==', ['id'], selectedFeatureId],
            100,
            70,
          ], 
          [
            'interpolate', 
            ['linear'], 
            ['get', value],
            0, 70,
            1, 50,
          ],
        ],
        'fill-color-transition': {delay: 0, duration: 0},
        'fill-opacity': [
          'case',
          ['==', ['id'], selectedFeatureId],
          0.85,
          0.7,
        ], 
      }
    }
    return layer
  }, [value, selectedFeature])

  const kelownaDaOutlineLayer: LineLayer = {
    id: 'kelownaDaOutlineLayer' + ['get', 'id'],
    type: 'line',
    paint: {
      'line-color': '#000000',
      'line-width': [
        'interpolate', 
        ['linear'], 
        ["zoom"],
        9, 0.3, 
        12, 2,
      ],
    }
  }

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': zoom
    }
  };

  function changeCurrentLocation(location: Location){
    if(!mapFlying){
      setSelectedFeature(null)
      setCurrentLocation(location)
    }
  }

  useMemo(() => {
    if(mapRef.current == undefined){return}
    setMaxBounds(undefined)
    setMapFlying(true)
    mapRef.current?.flyTo({
      center: {
        lng: currentLocation.lng, 
        lat: currentLocation.lat
      }, 
      zoom: currentLocation.zoom,
      pitch: 0,
      bearing: 0
    })
  }, [currentLocation])

  function onMapMoveEnd(){
    if(mapFlying){
      setMaxBounds(currentLocation.bounds)
      setMapFlying(false)
      // mapRef.current?.easeTo({
      //   pitch: 0,
      //   bearing: 0
      // })
    }
  }

  const onMapLoad = useCallback(() => {
    mapRef.current?.getMap().addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
      'maxzoom': 14
    });
    mapRef.current?.getMap().setTerrain({
      'source': 'mapbox-dem',
      'exaggeration': 1.5
    });
    mapRef.current?.on('move', () => {
      if(mapRef.current){
        setLng(mapRef.current.getCenter().lng);
        setLat(mapRef.current.getCenter().lat);
        setZoom(mapRef.current.getZoom());
      }
    });
  }, []);

  function onMapClick(e: MapLayerMouseEvent){
    console.log(e)
    if (e.features == null || e.features?.length == 0){
      setSelectedFeature(null)
    }
    else{
      const feature = e.features[0]
      setSelectedFeature(feature)
    }
  }

  return (
    <div className='map-container' style={{pointerEvents: mapFlying ? 'none' : 'auto'}}>
      <MapboxMap
        id='map'
        ref={mapRef}
        interactive={true}
        interactiveLayerIds={['vancouverDaLayer', 'kelownaDaLayer']}
        onLoad={onMapLoad}
        onClick={onMapClick}
        onMoveEnd={onMapMoveEnd}
        mapboxAccessToken="pk.eyJ1Ijoic3R1bWNnIiwiYSI6ImNrcDRtdHE1ZjBiYTkyeHQ4NG5sc3VpM2MifQ.N_cSsEXlTBk7q92Xdpfbug"
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoom,
        }}
        maxBounds={maxBounds}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <Layer {...skyLayer} />
        <NavigationControl position='top-right' visualizePitch={true}/>
        <FullscreenControl position='top-right'/>
        <div className='map-controls'>
          <div className='map-info'>
            Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom: {zoom.toFixed(2)}
          </div>
          {selectedFeature != null && (
            <div className='selection-info'>
              <h3>ID: {selectedFeature.id}</h3>
              <p>Region: {selectedFeature.properties?.['csdName']}</p>
              <p>Value 1: {selectedFeature.properties?.['value1']}</p>
              <p>Value 2: {selectedFeature.properties?.['value2']}</p>
              <p>Value 3: {selectedFeature.properties?.['value3']}</p>
            </div>
          )}
          <br/>
          <div className="map-button disabledControl" onClick={() => changeCurrentLocation(kelowna)}>Kelowna</div>
          <div className="map-button" onClick={() => changeCurrentLocation(vancouver)}>Vancouver</div>
          <br/>
          <div className="map-button disabledControl" onClick={() => setValue('value1')}>Value1</div>
          <br/>
          <div className="map-button disabledControl" onClick={() => setValue('value2')}>Value2</div>
          <br/>
          <div className="map-button disabledControl" onClick={() => setValue('value3')}>Value3</div>
        </div>
        {
          vancouverDALayer != null && vancouverGeoData != null &&(
            <Source type="geojson" data={vancouverGeoData}>
              <Layer {...vancouverDALayer}/>
              <Layer {...vancouverDaOutlineLayer}/>
            </Source>
          )
        }
        {
          kelownaDALayer != null && kelownaGeoData != null &&(
            <Source type="geojson" data={kelownaGeoData}>
              <Layer {...kelownaDALayer}/>
              <Layer {...kelownaDaOutlineLayer}/>
            </Source>
          )
        }
        <div className='bottom-box'>
          {barPlotData != null && (
            <ParentSize>
              {({width: width, height: height}) => (
                <Graph width={width} height={height} data={barPlotData} value={value} selected={selectedFeature == null ? null : '' + selectedFeature.id}/>
              )}
            </ParentSize>
          )}
        </div>
      </MapboxMap>
    </div>
  )
}
export default BarPlotMap