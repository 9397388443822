import { JwtPayload, jwtDecode } from "jwt-decode";

export interface AuthPayload extends JwtPayload {
  sub: string,
  email: string,
  firstName: string,
  lastName: string,
  role: string,
}

export function decodeJWT(jwt: string): AuthPayload {
  return jwtDecode(jwt) as AuthPayload
}