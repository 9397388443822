import { useMemo, useState } from "react";
import { Group } from "@visx/group";
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { schemeSet1 } from 'd3-scale-chromatic';

type CategoryDataPoint = {
  categoryName: string;
  categoryTitle: string;
  value: number;
}

const PercentageBar = ({height, width, data}: {
  height: number, 
  width: number, 
  data: CategoryDataPoint[],
}) => {
  const xMax = width;
  const yMax = height;

  const [hoveredCategory, setHoveredCategory] = useState<null | CategoryDataPoint>(null)

  const displayText = useMemo(() => {
    if (hoveredCategory == null) return ''
    return `${hoveredCategory.categoryTitle}: ${Math.round(hoveredCategory.value / data.map(d=>d.value).reduce((a,b)=>a+b, 0) * 100)}%`
  }, [data, hoveredCategory])

  const xScale = useMemo(() => {
    return scaleLinear({
      range: [0, xMax],
      domain: [0, data.map(d=>d.value).reduce((a,b)=>a+b)],
    })
  },[xMax, data]);

  const colorScale = scaleOrdinal({
    domain: data.map(d=>d.categoryName),
    range: Object.assign([], schemeSet1),
  });

  return (
    <>
      <svg width={width} height={height}>
        <Group transform={`matrix(1 0 0 -1 0 ${yMax})`}>
          {data.map((dataPoint, index, data) => {
            return (
              <>
                <rect 
                  height={height}
                  y={0}
                  width={xScale(dataPoint.value)}
                  x = {xScale(data.slice(0, index).map(d=>d.value).reduce((a,b)=>a+b, 0))}
                  fill = {colorScale(dataPoint.categoryName)}
                  opacity={dataPoint == hoveredCategory ? 1 : 0.8}
                  stroke='black'
                  strokeWidth={dataPoint == hoveredCategory ? 1 : 0}
                  onMouseEnter={(e) => {
                    if (e) {
                      setHoveredCategory(dataPoint)
                    }
                  }}
                  onMouseLeave={()=>{
                    setHoveredCategory(null)
                  }}
                />
              </>
            );
          })}
        </Group>
      </svg>
        <p style={{height:'2em', margin:'0px'}}>{displayText}</p>
    </>
  );
}

export default PercentageBar