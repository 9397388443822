import './css/Button.css'

function Button(
  {text, onClick}: {
    text: string, 
    onClick: () => void, 
  }
){  
  return(
    <div className={`button`} onClick={() => onClick()}>
      {text}
    </div>
  )
}

export default Button