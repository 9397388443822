import { useState } from "react";
import { Link } from 'react-router-dom'
import NavBar from "./NavBar"
import { useNavigate, Navigate } from "react-router-dom";

function Login() {

  const navigate = useNavigate()

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [output, setOutput] = useState('')

  if (localStorage.getItem('jwt') != null){
    return (<Navigate to='/'></Navigate>)
  }

  function submit() {
    let r_status: number
    fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email, password: pass})
    })
    .then(r => {
      r_status = r.status
      return r.json()
    })
    .then(r => {
      console.log(r_status)
      console.log(r)
      if (r_status == 201) {
        localStorage.setItem('jwt', r.jwt)
        navigate('/')
      } else {
        setOutput(r.message)
      }
    })
  }

  return(
    <>
      <NavBar active='Login'/>
      <div className='content'>
        <h2>Login</h2>
        <label>Email:</label>
        <input type='text' value={email} onChange={ev => setEmail(ev.target.value)}/>
        <br />
        <label>Password:</label>
        <input type='password' value={pass} onChange={ev => setPass(ev.target.value)}/>
        <br />
        <button onClick={submit}>Submit</button>
        <p>{output}</p>
        <Link to='/forgotPassword'>Forgot Password</Link>
        <br />
        <Link to='/requestAccess'>Request Access</Link>
      </div>
    </>
  )
}

export default Login