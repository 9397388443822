import MapboxMap, { FullscreenControl, Layer, LngLatBoundsLike, LngLatLike, MapRef, NavigationControl, SkyLayer, useControl } from 'react-map-gl';
import NavBar from "./NavBar"
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {MapboxOverlay, MapboxOverlayProps} from '@deck.gl/mapbox/typed';
import {H3HexagonLayer} from '@deck.gl/geo-layers/typed';
import {GeoJsonLayer} from '@deck.gl/layers/typed';
import {Feature} from 'geojson';
import colormap from 'colormap';
import { animate } from 'framer-motion';
import Dropdown from './components/Dropdown';
import Button from './components/Button';
import './css/Map.css';
import './css/ModelHex.css';
import TimeSlider from './components/TimeSlider';
import { cellToBoundary } from 'h3-js';
// import HourlyLineChart from './components/HourlyLineChart';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import HourlyStackedAreaChart from './components/HourlyStackedAreaChart';
import { /*getFullHueColorMapColor,*/ hexStringToRGBArray, kellyColorsHex } from './utils/ColorUtils';
import DropdownMultiple from './components/DropdownMultiple';
import { useMeasure } from '@uidotdev/usehooks';
import HourlyMultiLineChart from './components/HourlyMultiLineChart';
import YearSlider from './components/YearSlider';

function DeckGLOverlay(props: MapboxOverlayProps & {
  interleaved?: boolean;
}) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

export function ModelHex2Map() {

  const animationTime = 250;

  const mapRef = useRef<MapRef>(null);
  const [sideBarRef, sideBarMeasure] = useMeasure()

  type Location = {
    lng: number
    lat: number
    zoom: number
    bounds: LngLatBoundsLike
  }

  const kelowna: Location = useMemo(() => { 
    return{
      lng: -119.5333,
      lat: 49.9313,
      zoom: 9.08,
      bounds: [[-120.75, 49.5], [-118.25, 50.4]],
    }
  }, [])
  
  const vancouver: Location = useMemo(() => {
    return {
      lng: -122.9307,
      lat: 49.1910,
      zoom: 9.82,
      bounds: [[-124, 48.5], [-121.75, 49.7]],
    }
  }, [])

  const maxTimeIndex = 24;

  const [locationDropdownValue, setLocationDropdownValue] = useState<string | null>('Kelowna')
  const [zoomLevelDropdownValue, setZoomLevelDropdownValue] = useState<string>('8')
  const [currentLocation, setCurrentLocation] = useState<Location>(kelowna)
  const [lng, setLng] = useState(currentLocation.lng);
  const [lat, setLat] = useState(currentLocation.lat);
  const [zoom, setZoom] = useState(currentLocation.zoom);
  const [maxBounds, setMaxBounds] = useState<LngLatBoundsLike | undefined>(undefined);
  const [mapFlying, setMapFlying] = useState<boolean>(false);
  const [showSideBar, setShowSidebar] = useState<boolean>(false);
  const [sideBarWasShown, setSideBarWasShown] = useState<boolean>(false);
  const [mapCenterOffset, setMapCenterOffset] = useState<number>(0);
  const [selectedSideBarTab, setSelectedSideBarTab] = useState<string>('activities');

  const [modelData, setModelData] = useState<{hex: string, data: {total: number[], activityCounts: {[key: string]: number[]}}}[] | null>(null);
  const [hexHeights, setHexHeights] = useState<{hex: string, height: number}[] | null>(null)
  const [currentTimeIndex, setCurrentTimeIndex] = useState<number> (0);
  const [currentAnimationTimeIndex, setCurrentAnimationTimeIndex] = useState<number>(0)
  const [currentYear, setCurrentYear] = useState<number>(2020)
  const [animating, setAnimating] = useState<boolean>(false);
  const [heightMultiplier, setHeightMultiplier] = useState<number>(2);

  const {
    tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip,
  } = useTooltip<{total: number, activityData: {activity: string, count: number, color: string} | null}>();
  const [hoveredHex, setHoveredHex] = useState<string | null>(null)
  const [hoveredActivity, setHoveredActivity] = useState<string>('')
  const [selectedHexes, setSelectedHexes] = useState<string[]>([])
  const [selectedHexesData, setSelectedHexesData] = useState<{
    aggregated: {total: number[], activityCounts: {[key: string]: number}[]}, 
    seperate: {hex: string, values: number[]}[]
  }>({aggregated: {total: [], activityCounts: []}, seperate:[]})
  const [selectedActivities, setSelectedActivities] = useState<string[]>([])
  const [aggregate, setAggregate] = useState<boolean>(true)
  

  useEffect(() => {
    setSelectedHexes([])
      fetch('api/geoDatabase/getModelHourlyHexPopulation?'  + new URLSearchParams({
        zoom: zoomLevelDropdownValue,
        location: locationDropdownValue == 'Kelowna' ? 'ok' : 'va'
      }))
      .then(resp => resp.ok ? resp.json() : Promise.reject(resp))
      .then(json => setModelData(json))
      .catch(err => console.error('Could not load data', err));
  }, [zoomLevelDropdownValue, locationDropdownValue]);

  useEffect(() => {
    switch(zoomLevelDropdownValue){
      case '7': setHeightMultiplier(1); break
      case '8': setHeightMultiplier(2); break;
      case '9': setHeightMultiplier(4); break;
    }
  }, [zoomLevelDropdownValue])

  useMemo(() => {
    if(modelData == null){
      setHexHeights(null);
      return
    }
    setHexHeights(modelData.map(row => {
      let earlierValue = row.data.total[Math.floor(currentAnimationTimeIndex)]
      let laterValue = row.data.total[Math.ceil(currentAnimationTimeIndex)]
      if(selectedActivities.length > 0){
        earlierValue = selectedActivities.map(activity => row.data.activityCounts[activity][Math.floor(currentAnimationTimeIndex)]).reduce((a, b) => a + b)
        laterValue = selectedActivities.map(activity => row.data.activityCounts[activity][Math.ceil(currentAnimationTimeIndex)]).reduce((a, b) => a + b)
      }
      const currentValue = earlierValue + ((laterValue - earlierValue) * (currentAnimationTimeIndex % 1))
      return {
        hex: row.hex, 
        height: currentValue * heightMultiplier,
      }
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData, currentAnimationTimeIndex, selectedActivities])

  useMemo(() => {
    if(modelData == null || hexHeights == null) return;
    animate(currentAnimationTimeIndex, currentTimeIndex, {
      duration: (animationTime / 1000) * Math.abs(currentAnimationTimeIndex - currentTimeIndex),
      ease: 'linear',
      onUpdate: animationTimeIndex => setCurrentAnimationTimeIndex(animationTimeIndex)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeIndex])

  useMemo(() => {
    if(sideBarWasShown == showSideBar) return
    const mapWidth = parseInt(mapRef.current?.getCanvas().style.width ?? '0')
    const mapHeight = parseInt(mapRef.current?.getCanvas().style.height ?? '0')
    if(showSideBar){
      if(sideBarMeasure.width == null) return
      setMapCenterOffset(sideBarMeasure.width/2)
      mapRef.current?.flyTo({
        center: mapRef.current?.unproject([(mapWidth / 2) + sideBarMeasure.width/2, mapHeight / 2])
      })
    } else {
      if(mapWidth == null) return
      mapRef.current?.flyTo({
        center: mapRef.current?.unproject([(mapWidth / 2) - mapCenterOffset, mapHeight / 2])
      })
      setMapCenterOffset(0)
    }
    setSideBarWasShown(showSideBar)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideBarMeasure])

  useMemo(() => {
    if(currentTimeIndex < 0){
      setCurrentAnimationTimeIndex(maxTimeIndex)
      setCurrentTimeIndex(maxTimeIndex)
    }
    if(currentTimeIndex > maxTimeIndex){
      setCurrentAnimationTimeIndex(0)
      setCurrentTimeIndex(0);
    }
    if(animating){
      if (currentTimeIndex > maxTimeIndex){
        setTimeout(() => {setCurrentAnimationTimeIndex(0), setCurrentTimeIndex(0);}, animationTime)
      } else{
        setTimeout(() => setCurrentTimeIndex(currentTimeIndex + 1), animationTime)
      }
    }
  }, [animating, currentTimeIndex])

  useEffect(() => {
    if (modelData == null) return
    const selectedHexRows = modelData.filter(row => selectedHexes.includes(row.hex) || selectedHexes.length == 0).map(row => {return {
      hex: row.hex,
      total: row.data.total, 
      activityCounts: row.data.total.map((_, index) => {
        const countsAtTimeIndex: {[key: string]: number} = {}
        Object.keys(row.data.activityCounts).forEach((key) => {
          countsAtTimeIndex[key] = row.data.activityCounts[key][index]
        })
        return countsAtTimeIndex
      })
    }});
    const aggregated = selectedHexRows?.reduce((a, b) => {
      return{
        hex: '',
        total: a.total.map((value, index) => value + b.total[index]).slice(0, 25),
        activityCounts: a.activityCounts.map((value, index) => {
          Object.keys(value).forEach((key) => {
            value[key] += b.activityCounts[index][key]
          })
          return value
        }).slice(0, 25)
      }
    }) ?? {total: [], activityCounts: []}
    if (selectedHexes.length == 0){
      aggregated.activityCounts = aggregated.activityCounts.map((activityCounts, index) => {return {...activityCounts, 'travelling': aggregated.total[0] - aggregated.total[index]}}) 
    }
    const seperate = selectedHexes.length == 0 
    ? [{
      hex: 'total', 
      values: selectedActivities.length == 0 
        ? aggregated.total 
        : aggregated.activityCounts.map(value => selectedActivities.map(activity => value[activity]).reduce((a, b) => a + b))
    }]
    : selectedHexRows?.map(row => {
      return{
        hex: row.hex,
        values: selectedActivities.length == 0
        ? row.total
        : row.activityCounts.map(value => selectedActivities.map(activity => value[activity]).reduce((a, b) => a + b))
      }
    })
    setSelectedHexesData({
      aggregated: aggregated,
      seperate: seperate
    });
  }, [modelData, selectedActivities, selectedHexes])

  const hexLayer = useMemo(() => {  
    if (hexHeights == null) return null
    const cmap = colormap(
      {
        colormap: [
          {index: 0, rgb: [235, 235, 100]},
          {index: 0.5, rgb: [34, 149, 170]},
          {index: 1, rgb: [10, 25, 85]},
        ],
        nshades: 100, 
        format: 'rgba'
      }
    );
    return new H3HexagonLayer({
      id: 'hexLayer',
      data: hexHeights.map(row => {return {
        hex: row.hex,
        height: row.height,
      }}),
      pickable: true,
      wireframe: false,
      filled: true,
      extruded: true,
      getHexagon: d => d.hex,
      //@ts-expect-error doesn't like the slice for some reason
      getFillColor: d => selectedHexes.includes(d.hex)
        ? (aggregate ? [0, 0, 0, 0] : (
          (hoveredHex == d.hex || !selectedHexes.includes(hoveredHex ?? '')) && (selectedHexesData.seperate.map(row => row.hex).includes(d.hex ?? ''))
            ? hexStringToRGBArray(kellyColorsHex[selectedHexesData.seperate.map(row => row.hex).indexOf(d.hex) % kellyColorsHex.length])
            : [220, 220, 220]
        ))
        : (hoveredHex == d.hex 
          ? [200, 100, 100, 255]
          : cmap[Math.min(99, Math.max(0, Math.floor(d.height / 5000 * 100)))].slice(0, 3).concat(selectedHexes.length > 0 ? 50 : 255)
        ),
      getElevation: d => d.height,
      onClick: (d) => {
        if(selectedHexes.length == 0){
          setShowSidebar(true)
        }
        if (selectedHexes.includes(d.object?.hex)){
          setSelectedHexes(selectedHexes.filter(hex => hex != d.object?.hex))
          return
        }
        setSelectedHexes(selectedHexes.concat(d.object?.hex))
        hideTooltip()
      },
      onHover: (d) => {
        setHoveredHex(d.object?.hex)
        if(d.coordinate && d.object){
          const position = mapRef.current?.project(d.coordinate as LngLatLike)
          showTooltip({
            tooltipLeft: position?.x, 
            tooltipTop: position?.y,
            tooltipData: {
              total: Math.round(d.object.height / heightMultiplier),
              activityData: null,
            }
          })
        } else {
          hideTooltip()
        }
      },  
    });
  }, [hexHeights, selectedHexes, aggregate, hoveredHex, selectedHexesData.seperate, hideTooltip, showTooltip, heightMultiplier]);

  const barHexLayer = useMemo(() => {  
    const data = modelData?.filter(row => selectedHexes.includes(row.hex)).flatMap(row => {
      let cumulativeHeight = 0
      const activites = Object.keys(row.data.activityCounts)
      return activites.flatMap((key, index) => {
        const baseElevation = cumulativeHeight;
        const previousWholeTimeIndexValue = row.data.activityCounts[key][Math.floor(currentAnimationTimeIndex)]
        const nextWholeTimeIndexValue = row.data.activityCounts[key][Math.ceil(currentAnimationTimeIndex)]
        const height = (previousWholeTimeIndexValue + ((nextWholeTimeIndexValue - previousWholeTimeIndexValue) * (currentAnimationTimeIndex - Math.floor(currentAnimationTimeIndex)))) * heightMultiplier
        cumulativeHeight += height
        const colorHex = kellyColorsHex[index]
        return height > 0
          ? {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [cellToBoundary(row.hex, true).map(coord => coord.concat((baseElevation)))],
            },
            properties: {
              total: row.data.total[currentTimeIndex],
              hex: row.hex,
              activity: key,
              activityCount: row.data.activityCounts[key][currentTimeIndex],
              height: height,
              colorHex: colorHex,
              color: hoveredActivity == '' || key == hoveredActivity
                ? hexStringToRGBArray(colorHex) 
                : [220, 220, 220], 
            }
          } as Feature
          : []
      })
    })
    return new GeoJsonLayer({
      id: 'barHexLayer',
      data: aggregate ? data : [],
      pickable: true,
      stroked: false,
      filled: true,
      extruded: true,
      getFillColor: d => d.properties?.color,
      getElevation: d => d.properties?.height,
      onClick: (d) => {
        hideTooltip()
        setSelectedHexes(selectedHexes.filter(hex => hex != d.object.properties.hex))
      },
      onHover: (d) => {
        setHoveredActivity(d.object ? d.object.properties.activity : '')
        setHoveredHex(d.object ? d.object.properties.hex : null)
        if(d.coordinate && d.object){
          const position = mapRef.current?.project(d.coordinate as LngLatLike)
          showTooltip({
            tooltipLeft: position?.x, 
            tooltipTop: position?.y,
            tooltipData: {
              total: d.object.properties.total,
              activityData: {
                activity: d.object.properties.activity,
                count: d.object.properties.activityCount,
                color: d.object.properties.colorHex,
              }
            }
          })
        } else {
          hideTooltip()
        }
      },
    });
  }, [aggregate, currentAnimationTimeIndex, currentTimeIndex, heightMultiplier, hideTooltip, hoveredActivity, modelData, selectedHexes, showTooltip]);

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': zoom
    }
  };

  useMemo(() => {
    if(!mapFlying){
      setShowSidebar(false)
      setSideBarWasShown(false)
      if(locationDropdownValue == 'Kelowna' && currentLocation != kelowna){
        setCurrentLocation(kelowna)
      }
      if(locationDropdownValue == 'Vancouver' && currentLocation != vancouver){
        setCurrentLocation(vancouver)
      }
    }
  }, [currentLocation, kelowna, locationDropdownValue, mapFlying, vancouver])

  useMemo(() => {
    if(mapRef.current == undefined){return}
    setMaxBounds(undefined)
    setMapFlying(true)
    mapRef.current?.flyTo({
      center: {
        lng: currentLocation.lng, 
        lat: currentLocation.lat
      }, 
      zoom: currentLocation.zoom,
      pitch: 0,
      bearing: 0
    })
  }, [currentLocation])

  function onMapMoveEnd(){
    if(mapFlying){
      setMaxBounds(currentLocation.bounds)
      setMapFlying(false)
      mapRef.current?.easeTo({
        pitch: 50,
        bearing: 0
      })
    }
  }

  const onMapLoad = useCallback(() => {
    mapRef.current?.on('move', () => {
      if(mapRef.current){
        setLng(mapRef.current.getCenter().lng);
        setLat(mapRef.current.getCenter().lat);
        setZoom(mapRef.current.getZoom());
      }
    });
  }, []);

  return (
    <div className='map-container' style={{pointerEvents: mapFlying ? 'none' : 'auto'}}>
      <MapboxMap
        id='map'
        ref={mapRef}
        interactive={true}
        interactiveLayerIds={['hexDataLayer']}
        onLoad={onMapLoad}
        onMoveEnd={onMapMoveEnd}
        onMoveStart={() => setHoveredHex(null)}
        mapboxAccessToken="pk.eyJ1Ijoic3R1bWNnIiwiYSI6ImNrcDRtdHE1ZjBiYTkyeHQ4NG5sc3VpM2MifQ.N_cSsEXlTBk7q92Xdpfbug"
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoom,
        }}
        maxBounds={maxBounds}
        mapStyle="mapbox://styles/stumcg/clwl4zrxd00j801rb65kc9ui7"
      >
        <Layer {...skyLayer} />
        <DeckGLOverlay interleaved={true} layers={[barHexLayer, hexLayer]} />
        <NavigationControl position='bottom-left' visualizePitch={true}/>
        <FullscreenControl position='bottom-left'/>
        <div className='map-controls' style={{'display': 'flex'}}>
          <div style={{position: 'relative'}}>
            {tooltipOpen && tooltipData && !tooltipData.activityData && (
              <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
                {tooltipData.total}
              </TooltipWithBounds>
            )}
            {tooltipOpen && tooltipData && tooltipData.activityData && (
              <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
                <div style={{'width': 'max-content'}}>
                  <p>Total: {tooltipData.total}</p>
                  <p>
                    <span style={{'color': tooltipData.activityData.color}}>
                      {tooltipData.activityData.activity}: {tooltipData.activityData.count} | {
                        tooltipData.activityData.count / tooltipData.total < 0.01
                          ? Math.round(tooltipData.activityData.count / tooltipData.total * 10000) / 100
                          : Math.round(tooltipData.activityData.count / tooltipData.total * 100)
                        }%
                    </span>
                  </p>
                </div>
              </TooltipWithBounds>
            )}
          </div>
          <div style={{'flex': 'auto'}}>
            <span style={{'fontSize': '20px'}}>
              <div style ={{'float': 'left', textAlign: 'left'}}>
                <div className='dropdown-container'>
                  <Dropdown
                    name={'Location'}
                    options={['Kelowna', 'Vancouver']}
                    value={locationDropdownValue}
                    onChange={(value) => setLocationDropdownValue(value)}
                    anyOption={false}
                  />
                </div>
                <div className='dropdown-container'>
                  <Dropdown
                    name={'Zoom'}
                    options={['7', '8', '9']}
                    value={zoomLevelDropdownValue}
                    onChange={(value) => {if(value != null) setZoomLevelDropdownValue(value)}}
                    anyOption={false}
                  />
                </div>
                {modelData != null && (
                  <div className='dropdown-container'>
                    <DropdownMultiple
                      name={'Activity Type'} 
                      options={Object.keys(modelData[0].data.activityCounts)}
                      values={selectedActivities}
                      onChange={(values) => setSelectedActivities(values)}
                    />
                  </div>
                )}
                <div className='dropdown-container'>
                    <Dropdown
                      name={'Aggregate'} 
                      anyOption={false}
                      options={['Aggregate', `Don't Aggeregate`]}
                      value={aggregate ? 'Aggregate' : `Don't Aggeregate`}
                      onChange={(value) => setAggregate(value == 'Aggregate')}
                    />
                  </div>
                <div style={{'margin': '5px', 'marginTop': '0', 'width':'fit-content'}}>
                  <Button text={animating ? 'Stop' : 'Start'} onClick={() => setAnimating(!animating)}/>
                </div>
                <div style={{'margin': '5px', 'marginTop': '0', 'width':'fit-content'}}>
                  <Button text={'Next'} onClick={() => {setAnimating(false); setCurrentTimeIndex(currentTimeIndex + 1)}}/>
                </div>
                <div style={{'margin': '5px', 'marginTop': '0', 'width':'fit-content'}}>
                  <Button text={'Previous'} onClick={() => {setAnimating(false); setCurrentTimeIndex(currentTimeIndex - 1)}}/>
                </div>
                <div style={{'margin': '5px', 'marginTop': '0', 'width':'fit-content'}}>
                  <Button text={'Reset'} onClick={() => {setAnimating(false); setCurrentTimeIndex(0)}}/>
                </div>
                <div style={{'margin': '5px', 'marginTop': '0', 'width':'fit-content'}}>
                  <Button text={'Clear Selection'} onClick={() => setSelectedHexes([])}/>
                </div>
              </div>
              <br />
            </span>
            <div style = {{'position': 'relative', 'height': '100%', 'width': '100%'}}>
              <div className='bottom-slider-container'>
                <TimeSlider
                  value={currentTimeIndex}
                  onChange={(v) => setCurrentTimeIndex(v)}
                />
                <YearSlider
                  value={currentYear}
                  onChange={(v) => setCurrentYear(v)}
                />
              </div>
            </div>
          </div>
          <div ref={sideBarRef} className={`right-sidebar ${!showSideBar && 'right-sidebar-collapsed'}`} style={{'fontSize': '20px', backgroundColor: showSideBar ? 'lightgrey' : 'white'}}>
            {!showSideBar && (
              <div onClick={() => setShowSidebar(true)} style={{'float': 'left', 'cursor': 'pointer'}}>&#x2199;</div>
            )}
            {showSideBar && (
              <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row', margin: '0 1.4em 0 0.3em'}}>
                  <div onClick={() => setShowSidebar(false)} style={{'float': 'left', 'cursor': 'pointer', padding: '5px 10px 0 5px'}}>&#x2715;</div>
                  <div 
                    className={`sidebar-tab ${selectedSideBarTab == 'activities' && 'sidebar-tab-selected'}`}
                    onClick={() => setSelectedSideBarTab('activities')}
                  >
                    Activities
                  </div>
                  <div 
                    className={`sidebar-tab ${selectedSideBarTab == 'emissions' && 'sidebar-tab-selected'}`}
                    onClick={() => setSelectedSideBarTab('emissions')}
                  >
                    Emissions
                  </div>
                </div>
                <div style={{flexGrow: '1', overflow: 'scroll', backgroundColor: 'white', borderRadius: '0.3em 0.3em 0.7em 0.7em'}}>
                  {selectedSideBarTab == 'activities' && (
                    <>
                      <div style={{'float': 'left'}}>
                          <h3 style={{'margin': '0.5em 1em'}}>
                            {selectedHexes.length == 0
                              ? 'All Hexes'
                              : `Selected Hex${selectedHexes.length > 1 ? 's' : ''}`
                            }
                          </h3>
                        </div>
                      <br/>
                      {aggregate && selectedHexesData.aggregated.total.length > 0 && (
                        <HourlyStackedAreaChart
                          data= {selectedHexesData.aggregated}
                          width={parseInt(mapRef.current?.getCanvas().style.width ?? '0') * 0.33}
                          height={parseInt(mapRef.current?.getCanvas().style.width ?? '0') * 0.33 * 5/6}
                          hoveredActivity={hoveredActivity}
                          setHoveredActivity={setHoveredActivity}
                          currentTimeIndex={currentAnimationTimeIndex}
                          onSelectTimeIndex={setCurrentTimeIndex}
                        />
                      )}
                      {!aggregate && selectedHexesData.seperate.length > 0 && (
                        <HourlyMultiLineChart
                          data={selectedHexesData.seperate}
                          width={parseInt(mapRef.current?.getCanvas().style.width ?? '0') * 0.33}
                          height={parseInt(mapRef.current?.getCanvas().style.width ?? '0') * 0.33 * 5/6}
                          hoveredHex={hoveredHex}
                          setHoveredHex={setHoveredHex}
                          currentTimeIndex={currentAnimationTimeIndex}
                          onSelectTimeIndex={setCurrentTimeIndex}
                        />
                      )}
                      <div style={{'width': `${parseInt(mapRef.current?.getCanvas().style.width ?? '0') * 0.33}px`, 'padding': '1em'}}>
                        <h2 style={{lineHeight: 'normal'}}>placeholder for additional info here</h2>
                        <p>Qui anim aliqua qui sint esse ut est in amet cillum anim quis. Commodo anim velit pariatur eiusmod excepteur amet sit ea laborum ut cupidatat. Ad officia veniam qui deserunt ad nisi excepteur irure aliqua cupidatat esse. Incididunt nostrud duis fugiat culpa officia ut exercitation dolore proident pariatur do non deserunt. Duis non elit laborum deserunt reprehenderit velit eu ullamco culpa elit. Nisi eu id quis quis qui nulla incididunt eu elit dolor nostrud in.</p>
                        <p>Qui anim aliqua qui sint esse ut est in amet cillum anim quis. Commodo anim velit pariatur eiusmod excepteur amet sit ea laborum ut cupidatat. Ad officia veniam qui deserunt ad nisi excepteur irure aliqua cupidatat esse. Incididunt nostrud duis fugiat culpa officia ut exercitation dolore proident pariatur do non deserunt. Duis non elit laborum deserunt reprehenderit velit eu ullamco culpa elit. Nisi eu id quis quis qui nulla incididunt eu elit dolor nostrud in.</p>
                      </div>
                    </>
                  )}
                  {selectedSideBarTab == 'emissions' && (
                    <>
                      <div style={{'float': 'left'}}>
                          <h3 style={{'margin': '0.5em 1em'}}>Info about Emissions</h3>
                        </div>
                      <br/>
                      <div style={{'width': `${parseInt(mapRef.current?.getCanvas().style.width ?? '0') * 0.33}px`, 'padding': '1em'}}>
                        <h2 style={{lineHeight: 'normal'}}>placeholder for additional info here</h2>
                        <p>Qui anim aliqua qui sint esse ut est in amet cillum anim quis. Commodo anim velit pariatur eiusmod excepteur amet sit ea laborum ut cupidatat. Ad officia veniam qui deserunt ad nisi excepteur irure aliqua cupidatat esse. Incididunt nostrud duis fugiat culpa officia ut exercitation dolore proident pariatur do non deserunt. Duis non elit laborum deserunt reprehenderit velit eu ullamco culpa elit. Nisi eu id quis quis qui nulla incididunt eu elit dolor nostrud in.</p>
                        <p>Qui anim aliqua qui sint esse ut est in amet cillum anim quis. Commodo anim velit pariatur eiusmod excepteur amet sit ea laborum ut cupidatat. Ad officia veniam qui deserunt ad nisi excepteur irure aliqua cupidatat esse. Incididunt nostrud duis fugiat culpa officia ut exercitation dolore proident pariatur do non deserunt. Duis non elit laborum deserunt reprehenderit velit eu ullamco culpa elit. Nisi eu id quis quis qui nulla incididunt eu elit dolor nostrud in.</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </MapboxMap>
    </div>
  )
}

function ModelHex2(){
  return (
    <>
      <NavBar active='ModelHex2'/>
      <div className='content-fullscreen'>
        <ModelHex2Map/>
      </div>
    </>
  )
}

export default ModelHex2
