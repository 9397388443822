import { Group } from "@visx/group";
import Pie from '@visx/shape/lib/shapes/Pie';
import { useTooltip, Tooltip } from '@visx/tooltip';
import { useEffect, useMemo, useState } from "react";
import { localPoint } from '@visx/event';
import {createHueMapFromPieData, hslToHex} from '../utils/ColorUtils'
import {animate, transform} from 'framer-motion'

export type SunburstDataPoint = {
  title: string;
  value: number;
  children: SunburstDataPoint[];
}

function calcRadius(layerDepth: number, layerSize: number, margin: number): {inner: number, outer: number}{
    if (layerDepth < 0){
      return {inner: 0, outer: 1}
    }
    const innerRadius = (layerSize + margin) * (layerDepth)
    return {inner: innerRadius, outer: innerRadius + layerSize}
}

function getDataPointByIndexList(indexList: number[], root: SunburstDataPoint): SunburstDataPoint {
  let dataPoint = root
  for(let i = 0; i < indexList.length; i++){
    dataPoint = dataPoint.children[indexList[i]]
  }
  return dataPoint
}

const Sunburst = ({ 
  root,
  radius, 
  layersNum, 
  margin, 
  currentRoot, 
  setCurrentRoot,
  rootDepth,
  setRootDepth,
  hoveredHex, 
  setHoveredHex,
  externalNewRoot,
  setCurrentLayer1Data,
}: { 
  root: SunburstDataPoint; 
  radius: number; 
  layersNum: number; 
  margin: number; 
  currentRoot: SunburstDataPoint;
  setCurrentRoot: (currentRoot: SunburstDataPoint) => void;
  rootDepth: number;
  setRootDepth: (rootDepth: number) => void;
  hoveredHex: string | null;
  setHoveredHex: (hoveredHex: string | null) => void;
  externalNewRoot: {data: SunburstDataPoint, index: number} | null,
  setCurrentLayer1Data: (data: SunburstDataPoint[]) => void,
}) => {
  const {
    tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip,
  } = useTooltip();

  const [layerSize, setCurrentLayerSize] = useState<number>(0);

  const [indexList, setIndexList] = useState<number[]>([0])

  const [animationProgress, setAnimationProgress] = useState<number>(1)
  const [animateDirection, setAnimateDirection] = useState<number>(0)

  const [previousFirstLayerArcAngles, setPreviousFirstLayerArcAngles] = useState<{startAngle: number, endAngle: number}[] | null>(null)
  const [currentFirstLayerArcAngles, setCurrentFirstLayerArcAngles] = useState<{startAngle: number, endAngle: number}[] | null>(null)
  const [previousRootIndex, setPreviousRootIndex] = useState<number>(0)

  useMemo(() => {
    animate(0, 1, {
      duration: 1,
      ease: 'linear',
      onUpdate: progress => setAnimationProgress(progress)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoot])

  useEffect(() => {
    if(externalNewRoot != null){
      setAnimationProgress(0)
      setPreviousRootIndex(indexList[rootDepth])
      setPreviousFirstLayerArcAngles(currentFirstLayerArcAngles)
      setAnimateDirection(1)
      setRootDepth(rootDepth + 1)
      setIndexList(indexList.concat(externalNewRoot.index))
      setCurrentRoot(externalNewRoot.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalNewRoot])

  useMemo(() => {
    setCurrentLayerSize((radius - ((layersNum) * margin)) / (layersNum));
  }, [layersNum, margin, radius]);

  const chart = useMemo(() => {
    const SunburstLayer = (
      { data, parentHidden, startAngle, endAngle, layerDepth, parentColor}: 
      { data: SunburstDataPoint[]; parentHidden: boolean; startAngle: number; endAngle: number; layerDepth: number; parentColor?: string}
    ) => {
      const radius = calcRadius(layerDepth, layerSize, margin);
      const previousRadius = calcRadius(layerDepth + animateDirection, layerSize, margin)
      const totalAngle = (endAngle - startAngle);
      const parent = getDataPointByIndexList(indexList.slice(1, rootDepth + layerDepth), root)
      const dataSum = data.reduce((sum, dataPoint) => sum + dataPoint.value, 0)
      if (layerDepth > 0 && dataSum < parent.value){
        data = data.concat({title: 'done', value: parent.value - dataSum, children: []})
      }
      if (layerDepth == 1 && !parentHidden){
        setCurrentLayer1Data(data)
      }
      const hueMap = createHueMapFromPieData(data.map(dataPoint => dataPoint.value))
      return (
        <Pie
          data={data}
          pieValue={d => d.value}
          outerRadius={transform([0, 1], [previousRadius.outer, radius.outer])(animationProgress)}
          innerRadius={transform([0, 1], [previousRadius.inner, radius.inner])(animationProgress)}
          padAngle={2 * Math.asin(margin / (2 * radius.outer))}
        >
          {(pie) => {
            if(!parentHidden && layerDepth == 1 && totalAngle != 0){
              setCurrentFirstLayerArcAngles(pie.arcs.map(((arc, index) => {return {startAngle: arc.startAngle, endAngle: arc.endAngle, index: index}})))
            }
            return pie.arcs.map((initialArc, index, arcsList) => {
              const arc = structuredClone(initialArc)
              const arcs = structuredClone(arcsList)
              let newStartAngle = (arc.startAngle * totalAngle / (2 * Math.PI)) + startAngle;
              let newEndAngle = arc.endAngle = (arc.endAngle * totalAngle / (2 * Math.PI)) + startAngle;
              let previousStartAngle = newStartAngle;
              let previousEndAngle = newEndAngle;
              let arcHidden = parentHidden;
              if(layerDepth == 0){
                if(index == indexList[rootDepth]){
                  arcHidden = false
                }
                newStartAngle = 0;
                newEndAngle = 2 * Math.PI;
                previousStartAngle = 0;
                previousEndAngle = 2 * Math.PI;
                if(animateDirection > 0 && previousFirstLayerArcAngles){
                  previousStartAngle = previousFirstLayerArcAngles[index].startAngle;
                  previousEndAngle = previousFirstLayerArcAngles[index].endAngle;
                  if(arcHidden){
                    if(previousFirstLayerArcAngles[indexList[rootDepth]].startAngle > previousStartAngle){
                      newEndAngle = 0
                    }else{
                      newStartAngle = 2 * Math.PI
                    }
                  }
                }else if(arcHidden){
                  newEndAngle = 0;
                  previousEndAngle = 0;
                }                
              }
              if(layerDepth == 1 && !arcHidden && animateDirection < 0){
                previousStartAngle = 0
                previousEndAngle = 0
                if(index == previousRootIndex){
                  previousEndAngle = 2 * Math.PI
                }else if(newStartAngle > arcs[previousRootIndex].startAngle){
                  previousStartAngle = 2 * Math.PI
                  previousEndAngle = 2 * Math.PI
                }
              }
              arc.startAngle = transform([0,1], [previousStartAngle, newStartAngle])(animationProgress)
              arc.endAngle = transform([0,1], [previousEndAngle, newEndAngle])(animationProgress)
              const arcPath = pie.path(arc);
              const arcKey = `arc-${arc.data.title.toString()}`;
              const color = parentColor ?? hslToHex(hueMap[index], 60, hoveredHex == arc.data.title ? 40 : 65)
              let arcFill = color
              if (layerDepth == 1 && animateDirection < 0){
                arcFill = transform([0, 1], ['#e4e4e4', color])(animationProgress)
              }
              if (layerDepth == 0 && animateDirection > 0){
                arcFill = transform([0, 1], [color, '#e4e4e4'])(animationProgress)
              }
              if (layerDepth <= 0 || arc.data.title == parent.title  ) arcFill = '#e4e4e4'
              if (arc.data.title == 'done') arcFill = '#4e4e4e';
              // const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1 && radius.inner != radius.outer;
              // const [centroidX, centroidY] = pie.path.centroid(arc);
              const currentOpacity = layerDepth < (layersNum) ? 1 : 0
              const previousOpacity = (layerDepth + animateDirection) < layersNum ? 1 : 0
              const opacity = transform([0,1], [previousOpacity, currentOpacity])(animationProgress)
              return (
                <>
                  {arcPath && (
                    <g key={arcKey}>
                      <path
                        d={arcPath}
                        fill={arcFill}
                        opacity={opacity}
                        onMouseMove={(e) => { 
                          if(opacity == 1){
                            const svgCoords = localPoint(e)
                            if (hoveredHex != arc.data.title)
                              setHoveredHex(arc.data.title)
                            showTooltip({ tooltipLeft: svgCoords?.x, tooltipTop: svgCoords?.y, tooltipData: arc.data.value });
                          }
                        }}
                        onMouseLeave={() => { hideTooltip(); setHoveredHex(null);}}
                        onMouseDown={() => {
                          if(opacity == 1 && !arcHidden && !(rootDepth == 0 && layerDepth == 0) && arc.data.title != 'done'){
                            setAnimationProgress(0)
                            setPreviousRootIndex(indexList[rootDepth])
                            setPreviousFirstLayerArcAngles(currentFirstLayerArcAngles)
                            if(layerDepth != 0){
                              setAnimateDirection(1)
                              setRootDepth(rootDepth + 1)
                              setIndexList(indexList.concat(index))
                              setCurrentRoot(arc.data);
                            }else if(parent != null){
                              setAnimateDirection(-1)
                              setRootDepth(rootDepth - 1)
                              setIndexList(indexList.slice(0, -1))
                              setCurrentRoot(parent)
                            }
                            hideTooltip()
                          }
                        }} />
                      {layerDepth == 0 && !arcHidden && animationProgress == 1 && (
                        <text
                          x={0}
                          y={0}
                          dy=".33em"
                          fill={'#000000'}
                          fontSize={22}
                          textAnchor="middle"
                          pointerEvents="none"
                        >
                          {arc.data.value}
                        </text>
                      )}
                    </g>
                  )}
                  {(layerDepth == 0 || arc.data.children.length > 0) && (
                    <SunburstLayer
                      data={arc.data.children}
                      parentHidden={arcHidden}
                      startAngle={arc.startAngle}
                      endAngle={arc.endAngle}
                      layerDepth={layerDepth + 1}                  
                      parentColor={layerDepth > 0 ? color : undefined}                      
                    />
                  )}
                </>
              );
            });
          }}
        </Pie>
      );
    };
    return (
      <svg width={radius * 2} height={radius * 2}>
          <Group top={radius} left={radius}>
              <SunburstLayer
                data={[getDataPointByIndexList(indexList.slice(0, rootDepth), {title: 'rootParent', value: 0, children: [root]})]}
                parentHidden = {true}
                startAngle={0}
                endAngle={2 * Math.PI}
                layerDepth={-1}
              />
          </Group>
        </svg>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radius, indexList, rootDepth, root, layerSize, margin, animationProgress, hoveredHex,]);

  return (
    <>
      <div style={{position: 'relative'}}>
        {tooltipOpen && (
          <Tooltip left={tooltipLeft} top={tooltipTop}>
            <>{tooltipData}</>
          </Tooltip>
        )}
      </div>
      {chart}
    </>
  );
};


export default Sunburst