import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './css/Dropdown.css'
import useWindowDimensions from '../utils/windowDimensions'

function Dropdown(
  {name, options, value, onChange, anyOption = true, anyOptionName = 'Any'}: {
    name: string, 
    options: string[], 
    value: string | null, 
    onChange: (value: string | null) => void, 
    anyOption?: boolean,
    anyOptionName?: string
  }
){  
  const topRef = useRef<HTMLDivElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState<boolean>(false)

  const [shifted, setShifted] = useState<boolean>(false)
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    function closeDropdown(e: Event): void {
      if(
        topRef.current && !topRef.current.contains(e.target as Node) 
        && optionsRef.current && !optionsRef.current.contains(e.target as Node)
      ){
        setExpanded(false)
      }
    }
    document.addEventListener('click', closeDropdown)
    return () => document.removeEventListener('click', closeDropdown)
  }, [])

  useLayoutEffect(() => {
    if (!optionsRef.current || !topRef.current || !optionsRef.current.offsetParent) return
    if(shifted && topRef.current.offsetLeft + optionsRef.current.offsetWidth < optionsRef.current.offsetParent.clientWidth){
      optionsRef.current.style.left = `${topRef.current.offsetLeft}px`
      setShifted(false)
      return
    }
    const offEdge = optionsRef.current.offsetLeft + optionsRef.current.offsetWidth >= optionsRef.current.offsetParent.clientWidth
    if(offEdge){
      setShifted(true)
      optionsRef.current.style.left = `${optionsRef.current.offsetLeft - optionsRef.current.offsetWidth + topRef.current.offsetWidth}px`
    }
  }, [expanded, shifted, windowDimensions.width])

  return(
    <div style={{'textAlign': 'left'}}>
      <div ref = {topRef} className={`top-div ${value != null && 'top-div-selected'}`} onClick={() => setExpanded(!expanded)}>
        {`${value == null ? name : value} ▾`}
      </div>
      {expanded && (
        <>
          <br/>
          <div ref={optionsRef} className='options-container'>
            {anyOption && (
              <div className='option-div' onClick={() => {setExpanded(false); onChange(null)}}>{anyOptionName}</div>
            )}
            {options.map(option => {
              return (
                <div className={`option-div ${value == option && 'option-div-selected'}`} onClick={() => {setExpanded(false); onChange(option)}}>
                  {option}
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default Dropdown