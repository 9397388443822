import ReactSlider from 'react-slider'
import './css/TimeSlider.css'

function convertHour24ToTimeString(index: number): string {
  const time24 = index % 24;
  const amPm = time24 < 12 ? 'am' : 'pm';
  return `${time24 % 12 == 0 ? 12 : time24 % 12}${amPm}`;
}

function TimeSlider({value, onChange}: {value: number, onChange: (value: number) => void}){
  return(
    <div className='slider-container'>
      <ReactSlider
        className="horizontal-slider"
        markClassName="mark"
        marks
        min={0}
        max={24}
        thumbClassName="thumb"
        trackClassName="track"
        renderThumb={(props) => <div {...props}><div className='thumb-pointer'>{convertHour24ToTimeString(value)}</div></div>}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

export default TimeSlider