import NavBar from "./NavBar"
import { decodeJWT } from "./Auth"
import { useNavigate, Navigate } from "react-router-dom"

function UserPage() {
  const navigate = useNavigate()
  function logout(){
    localStorage.removeItem('jwt')
    navigate('/')
  }
  
  const jwt = localStorage.getItem('jwt')
  if (jwt == null){
    return (<Navigate to='/'></Navigate>)
  } else {
    const authData = decodeJWT(jwt) 
    console.log(authData)
    return(
      <>
        <NavBar active='UserPage'/>
        <div className='content'>
          <h3>Email: {authData.email}</h3>
          <h3>First Name: {authData.firstName}</h3>
          <h3>Last Name: {authData.lastName}</h3>
          <h3>Role: {authData.role}</h3>
          <input type='button' onClick={logout} value='Logout'/>
        </div>
      </>
    )
  }
}

export default UserPage