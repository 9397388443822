import NavBar from './NavBar.tsx'
import './css/Home.css'

function Home(){
  return (
    <>
      <NavBar active='Home'/>
      <div className='content'>
        <h1>UBCO CAAF Geoportal</h1>
        <p>
          This site will serve as a public interface to interact with the results of the "Integrated Development of Transport Data,
          Model & Community Outreach Tool for Urban & Rural Regions" project, fufilling objective 6.
        </p>
        <h2>About the project</h2>
        <p>
          <b>Context</b>: Canada has adopted aggressive policies such as mixed land use developments and
          made investments e.g. $150 billion for transit network to reduce emissions from transportation.
          To achieve the target of net zero emissions economy, it is critical to invest and tailor policies for
          both larger and rural regions. Cities around the world have made tremendous investments in
          building transport models for better policy-making. The major limitations of these models are to
          consider urban landscape and population as exogenous inputs, and adopt an aggregate-level
          resolution; which might result-in poor forecasting accuracy and biased policies. As researchers
          were addressing the above fundamental limitations; in 2020, COVID-19 happened. This
          pandemic has significantly disrupted individuals travel behaviour. People have started to find
          ways to replace or complement their out-of-home activities such as commute trip and hospital
          visit with in-home activities such as telework and virtual care. Single-detached housing prices
          are sky rocketing and car sales in Canada are about 250% up right now. This pandemic is likely
          to induce significant longer-term behavioural changes, which might not be reversible in the next
          decade. However, due to the novelty of the pandemic, limited understanding exists. The need is
          to collect novel data such as in-home and out-of-home activity data and develop models
          accommodating their interactions for better behavioural representation of individuals, improved
          forecasting accuracy and effective policy-making.
        </p>
        <p>
          <b>Objectives</b>: The focus of this inter-disciplinary project will be to adopt an integrated approach to
          collect newer data and develop a novel modelling system to better assess travel behaviour,
          followed by a holistic method to develop emerging policies to reduce vehicular emissions. This
          study will evaluate the evolution and the longer-term effects of COVID-19 on travel behaviour
          including housing, vehicle ownership, in-home and out-of-home activities, mode choice, and
          how that impact the carbon footprint. The scope includes Metro Vancouver and Central
          Okanagan regions from BC.
        </p>    
        <p>
        The objectives are:
        </p>
        <ol>
          <li>
            To collect newer data that is tailored to gather information on in-home and out-of-home
            activity, residential location, and vehicle ownership, and adjustments in behavior during
            the COVID-19. To accommodate the longer-term impacts of this pandemic, a panel
            survey method will be adopted to understand the evolution of behaviour over time.
          </li>
          <li>
            To develop novel land use and vehicle ownership models for predicting urban densities
            and car usage
          </li>
          <li>
            To develop advanced travel demand forecasting models encompassing the interactions
            between in- and out-of-home activities for predicting when, where and how individuals
            are travelling; what vehicles and modes are used; and what distances are travelled
          </li>
          <li>
            To quantify vehicular emissions including GHG and criteria air contaminant pollutants
          </li>
          <li>
            To develop an interactive online tool and test different use cases including leveraging
            newer technologies (e.g. autonomous vehicles), dynamic strategies (e.g. virtual care and
            mobility pricing), and considering different sectors (e.g. health care sector) to reduce
            travel-related carbon footprint
          </li>
          <li>
            To develop techniques to disseminate the new knowledge including data, models and
            findings to stakeholders
          </li>
        </ol>
        <h2>Full Proposal Document</h2>
        <iframe className='pdf' src={'full_proposal.pdf'}>
          <p>pdf cannot be displayed, download <a href='full_proposal.pdf'>here</a></p>
        </iframe>
      </div>
    </>
  )
}

export default Home