import { ModelHex2Map } from './ModelHex2'
import { ModelHex3Map } from './ModelHex3.tsx'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import Logo from './components/Logo.tsx'
import './css/NewHome.css'
import { ModelHexMap } from './ModelHex.tsx'
import { animate, motion } from 'framer-motion'
import { useRef, useState } from 'react'

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -50; 
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

function Home(){
  const [menuBarAnimationProgress, setmenuBarAnimationProgress] = useState<number>(1);
  const logoHeight = 100;
  const menuSpacerRef = useRef<HTMLDivElement>(null)
  const menuBarRef = useRef<HTMLDivElement>(null)
  const menuBarParentRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <div ref={menuBarParentRef} className='menu-bar-parent'>
        <div style={{
          'position': 'relative',
          'top': (1 - Math.max(menuBarAnimationProgress*2 - 1, 0)) * -50,
          'opacity': Math.max(menuBarAnimationProgress*2 - 1, 0)
        }}>
          <Logo color='#2a74c8' height={logoHeight}/>
        </div>
        <motion.div 
          ref={menuSpacerRef}
          style={{height: logoHeight}} 
          className='menu-spacer'
          onViewportLeave={() => {
            animate(menuBarAnimationProgress, 0, {duration: 1, onUpdate: value => setmenuBarAnimationProgress(value)})}
          }
          onViewportEnter={() => {
            animate(menuBarAnimationProgress, 1, {duration: 1, onUpdate: value => setmenuBarAnimationProgress(value)})}
          }
          viewport={{amount:'all'}}
        />
          <div 
            ref={menuSpacerRef} 
            className='menu-bar' 
            style={{position: 'relative', left: -(1 - Math.min(menuBarAnimationProgress*1.25, 1)) * (
              (menuSpacerRef.current?.offsetLeft ?? 0) + (menuSpacerRef.current?.offsetWidth ?? 0) - ((menuBarRef.current?.offsetWidth ?? 0) + (menuBarParentRef.current?.offsetWidth ?? 0) / 2)
            )
          }}>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#maps' scroll={scrollWithOffset}>Maps</HashLink>
            </div>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#about' scroll={scrollWithOffset}>About</HashLink>
            </div>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#data' scroll={scrollWithOffset}>Data</HashLink>
            </div>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#prototypes' scroll={scrollWithOffset}>Prototypes</HashLink>
            </div>
            <div className='menu-link-container'>
              <HashLink className='menu-link' to='/#contact' scroll={scrollWithOffset}>Contact</HashLink>
            </div>
            <div className='menu-link-container'>
              <Link className='menu-link' to='/Login'>Admin</Link>
            </div>
          </div>
        </div>
      <div>
        <div style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
          Climate Mobility Research Network is a project involving researchers at the UBC Okanagan and Vancouver campuses. (introduction here) Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.
        </div>
        <div id='maps'>
          <div style={{'width':'80%', 'textAlign':'center', 'padding':'20px 10%', 'display': 'flex', 'justifyContent': 'space-between'}}>
            <div style={{'height': '60vh', 'width': '70%', 'backgroundColor': '#2a74c8', 'padding': '5px'}}>
              <ModelHex2Map/>
            </div>
            <div style={{'display':'flex', 'flexDirection':'column', 'justifyContent':'center', 'textAlign': 'center'}}>
              This map ... laboris mollit Lorem cupidatat
            </div>
          </div>
          <div style={{'width':'80%', 'textAlign':'center', 'padding':'20px 10%', 'display': 'flex', 'justifyContent': 'space-between'}}>
            <div style={{'display':'flex', 'flexDirection':'column', 'justifyContent':'center', 'textAlign': 'center'}}>
              This map... laboris mollit Lorem cupidatat
            </div>
            <div style={{'height': '60vh', 'width': '70%', 'backgroundColor': '#2a74c8', 'padding': '5px'}}>
              <ModelHex3Map/>
            </div>
          </div>
          <div style={{'width':'80%', 'textAlign':'center', 'padding':'20px 10%', 'display': 'flex', 'justifyContent': 'space-between'}}>
            <div style={{'height': '60vh', 'width': '70%', 'backgroundColor': '#2a74c8', 'padding': '5px'}}>
              <ModelHexMap/>
            </div>
            <div style={{'display':'flex', 'flexDirection':'column', 'justifyContent':'center', 'textAlign': 'center'}}>
              This map ... laboris mollit Lorem cupidatat
            </div>
          </div>
        </div>
        <div id='about' style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
          <h1 style={{'color': '#2a74c8'}}>About</h1>
          <p>More detailed information about the project here</p>
          <p>Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.</p>
          <p>Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.</p>
          <p>Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.</p>
          <p>Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.</p>
          <p>Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.</p>
          <p>Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.</p>
        </div>

        <div id='data' style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
          <h1 style={{'color': '#2a74c8'}}>Data</h1>
          <p>Information about data sources here</p>
          <p>Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.</p>
          <p>Magna esse consequat officia aliquip. Et magna et occaecat irure pariatur duis dolor. Cillum amet culpa id cupidatat esse enim. Do non eiusmod tempor id qui eu. Et nostrud ea proident Lorem irure reprehenderit eu quis exercitation excepteur.</p>
          <h3>Access Data (link to request access / login)</h3>
        </div>

        <div id='prototypes' style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
          <h1 style={{'color': '#2a74c8'}}>Prototypes</h1>
          <p>Links to old maps here: (maybe format this bit like a timeline?)</p>
          <h3><Link className='menu-link' to='/Map'>Old Map 1:</Link></h3>
          <p>Info about map Eu et minim reprehenderit ipsum veniam ad cupidatat ullamco cupidatat culpa ad amet.</p>
          <h3><Link className='menu-link' to='/Census'>Old Map 2:</Link></h3>
          <p>Info about map Eu et minim reprehenderit ipsum veniam ad cupidatat ullamco cupidatat culpa ad amet.</p>
          <h3><Link className='menu-link' to='#'>Old Map 3:</Link></h3>
          <p>Info about map Eu et minim reprehenderit ipsum veniam ad cupidatat ullamco cupidatat culpa ad amet.</p>
          <h3><Link className='menu-link' to='#'>Old Map 4:</Link></h3>
          <p>Info about map Eu et minim reprehenderit ipsum veniam ad cupidatat ullamco cupidatat culpa ad amet.</p>
        </div>

        <div id='contact' style={{'width':'60%', 'textAlign':'center', 'padding':'20px 20%'}}>
          <h1 style={{'color': '#2a74c8'}}>Contact</h1>
          <p>Email: asdl;fkjas;djklf</p>
          <p>Phone: adsklf;asdf</p>
          <p>Address?: asdflkj;</p>
          <p>Maybe we want contacts for each part of the project?</p>
          <p>Email: asdl;fkjas;djklf</p>
          <p>Phone: adsklf;asdf</p>
          <p>Address?: asdflkj;</p>
          <p>Email: asdl;fkjas;djklf</p>
          <p>Phone: adsklf;asdf</p>
          <p>Address?: asdflkj;</p>
          <p>Any other contect related info?</p>
        </div>
      </div>
    </>
  )
}

export default Home
