import { scaleLinear } from '@visx/scale';
import { Group } from "@visx/group";
// import { curveMonotoneX } from "@visx/curve";
// import { LinePath } from "@visx/shape";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { GridRows, GridColumns } from "@visx/grid";
import { useEffect, useMemo, useState } from "react";
import { localPoint } from '@visx/event';
import { AreaStack } from "@visx/shape";
import { /*getFullHueColorMapColor,*/ kellyColorsHex } from '../utils/ColorUtils';

function convertHour24ToTimeString(index: number): string {
  const time24 = index % 24;
  const amPm = time24 < 12 ? 'am' : 'pm';
  return `${time24 % 12 == 0 ? 12 : time24 % 12}${amPm}`;
}

function HourlyStackedAreaChart(
  {width, height, data, hoveredActivity, setHoveredActivity, currentTimeIndex, onSelectTimeIndex}: 
  {
    width: number, 
    height: number, 
    data: {total: number[], activityCounts: {[key: string]: number}[]}, 
    hoveredActivity: string,
    setHoveredActivity: (activity: string) => void, 
    currentTimeIndex: number, 
    onSelectTimeIndex: (timeIndex: number) => void})
{ 
  const [margin, setMargin] = useState<{top: number, right: number, bottom: number, left: number}>({top: 20, right: 20, bottom: 30, left: 50 })
  const [xMax, setXMax] = useState<number>(0)
  const [yMax, setYMax] = useState<number>(0)
  const [dataMax, setDataMax] = useState<number>(0)

  const [currentMarkerY, setCurrentMarkerY] = useState<number | null>(null)

  const {
    tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip,
  } = useTooltip<{activity: string, color: string}>();

  useMemo(() => setXMax(width - margin.left - margin.right), [margin, width]);
  useMemo(() => setYMax(height - margin.top - margin.bottom), [margin, height]);

  useMemo(() => setMargin({top: 20, right: 20, bottom: 30, left: 35 + (5 * Math.floor(Math.log10(dataMax ?? 1))) }), [dataMax])

  // scales
  const timeScale = useMemo(() => {
    return scaleLinear<number>({
      range: [0, xMax],
      domain: [0, data.total.length - 1],
  })}, [data.total.length, xMax]);
  
  const valueScale = useMemo(() => {
    const max = Math.max(...data.total) 
    const dataRange = max - Math.min(...data.total)
    setDataMax(max)
    return scaleLinear<number>({
      range: [0, yMax],
      domain: [max + (dataRange*0.1), 0],
  })}, [data, yMax]);
  
  useEffect(() => {
    const previousWholeTimeIndexValue = data.total[Math.floor(currentTimeIndex)]
    const nextWholeTimeIndexValue = data.total[Math.ceil(currentTimeIndex)]
    setCurrentMarkerY(valueScale(previousWholeTimeIndexValue + ((nextWholeTimeIndexValue - previousWholeTimeIndexValue) * (currentTimeIndex - Math.floor(currentTimeIndex)))))
  }, [currentTimeIndex, valueScale, data])

  const [hoveredTimeIndex, setHoveredTimeIndex] = useState<number | null>(0)

  function onChartClick(e: React.MouseEvent<SVGElement>) {
    const point = localPoint(e)
    if(!point) return;
    const x = timeScale.invert(point.x - margin.left);
    if(x < 0 || x > data.total.length - 1) return
    onSelectTimeIndex(Math.round(x))
  }

  function onChartHover(e: React.MouseEvent<SVGElement>) {
    const point = localPoint(e)
    if(!point) {
      setHoveredTimeIndex(null);
      return;
    }
    const x = timeScale.invert(point.x - margin.left);
    if(x < 0 || x > data.total.length - 1){
      setHoveredTimeIndex(null);
      return;
    }
    setHoveredTimeIndex(Math.round(x))
  }

  return (
    <>
      <div style={{position: 'relative'}}>
        {tooltipOpen && hoveredTimeIndex != null && tooltipData && (
          <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
            <div style={{'width': 'fit-content'}}>
              {tooltipData.activity != 'travelling' && (
                <>
                  <p>Total: {data.total[hoveredTimeIndex]}</p>
                  <p>
                    <span style={{'color': tooltipData.color}}>
                      {tooltipData.activity}: {data.activityCounts[hoveredTimeIndex][tooltipData.activity]} | {Math.round(data.activityCounts[hoveredTimeIndex][tooltipData.activity] / data.total[hoveredTimeIndex] * 100)}%
                    </span>
                  </p>
                </>
              )}
              {tooltipData.activity == 'travelling' && (
                <p>
                <span style={{'color': tooltipData.color}}>
                  {tooltipData.activity}: {data.activityCounts[hoveredTimeIndex][tooltipData.activity]}
                </span>
              </p>
              )}
            </div>
          </TooltipWithBounds>
        )}
      </div>
      <svg
        width={width}
        height={height}
        onClick={onChartClick}
        onMouseMove={onChartHover}
        onMouseLeave={() => setHoveredTimeIndex(null)}
      >
        <Group left={margin.left} top={margin.top}>
          <defs>
            <clipPath id="currentTimeClip">
              <rect x={0} y={0} width={timeScale(currentTimeIndex)} height={height} />
            </clipPath>
          </defs>
          <GridRows
            scale={valueScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <GridColumns
            scale={timeScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <AreaStack
            keys={Object.keys(data.activityCounts[0])}
            data={data.activityCounts.map((row, index) => {return {index: index, ...row}})}
            x={(d) => timeScale(d.data.index)}
            y0={(d) => valueScale(d[0])}
            y1={(d) => valueScale(d[1])}
          >
            {({ stacks, path }) => stacks.map((stack, index) => {
              const greyOut = hoveredActivity != '' && stack.key != hoveredActivity
              const color = greyOut
                ? '#DCDCDC'  
                : kellyColorsHex[index]
                // : getFullHueColorMapColor(
                //   ((index + 5) % stacks.length) + ((index % 2) * (Math.round(stacks.length / 4)) * 2),
                //   stacks.length,
                //   60, 
                //   50
                // )
              return (
                <path
                  d={path(stack) ?? ''}
                  key={`stack-${stack.key}`}
                  fill={color}
                  fillOpacity={0.65}
                  strokeWidth={greyOut ? 1 : 0}
                  stroke='black'
                  onMouseMove={(e) => {                
                    setHoveredActivity(stack.key)
                    if(hoveredTimeIndex){
                      showTooltip({
                        tooltipLeft: timeScale(hoveredTimeIndex) + margin.left, 
                        tooltipTop: localPoint(e)?.y, 
                        tooltipData: {activity: stack.key, color: color}
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    setHoveredActivity('')
                    hideTooltip()
                  }}
                />
              )
            })
          }
          </AreaStack>
          <AxisBottom
            top={yMax}
            scale={timeScale}
            tickValues={[0, 4, 8, 12, 16, 20, 24]}
            tickFormat={(index) => convertHour24ToTimeString(index.valueOf())}
          />
          <AxisLeft scale={valueScale} label='Population' labelOffset={15 + (5 * Math.floor(Math.log10(dataMax ?? 1)))}/>
          {currentMarkerY != null && (
            <line 
              x1={timeScale(currentTimeIndex)}
              x2={timeScale(currentTimeIndex)}
              y1={yMax}
              y2={currentMarkerY}  
              stroke={'black'}
              strokeWidth={2}
              style={{'pointerEvents': 'none'}}
            />
          )}
          {hoveredTimeIndex != null && (
            <line 
              x1={timeScale(hoveredTimeIndex)}
              x2={timeScale(hoveredTimeIndex)}
              y1={yMax}
              y2={valueScale(data.total[hoveredTimeIndex])}  
              stroke={'black'}
              strokeWidth={2}
              opacity={0.6}
              style={{'pointerEvents': 'none'}}
            />
          )}
        </Group>
      </svg>
    </>
  )
}

export default HourlyStackedAreaChart