import { useState } from "react";
import NavBar from "./NavBar"
import { Navigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string | null>(null)

  const jwt = localStorage.getItem('jwt')
  if (jwt != null){
    return (<Navigate to='/'></Navigate>)
  }

  function onSubmit(){
    fetch('/api/auth/generatePasswordResetToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email})
    })
    setMessage(`A password reset link has been sent to ${email}`)
  }

  return(
    <>
      <NavBar active=''/>
      <div className='content'>
        <h2>Forgot Password</h2>
        <label>Email:</label>
        <input type='text' value={email} onChange={ev => setEmail(ev.target.value)}/>
        <br />
        <input type='button' value='submit' onClick={onSubmit} />
        {message != null && (
          <p>{message}</p>
        )}
      </div>
    </>
  )
}

export default ForgotPassword