import './css/NavBar.css'
import { Link } from 'react-router-dom'
import { decodeJWT } from './Auth'


function NavBar({active}: {active: string}) {
  
  let user_tab_text = 'Login'
  let user_tab_link = '/Login'
  let showAdmin = false
  const jwt = localStorage.getItem('jwt')
  if(jwt != null){
    const authData = decodeJWT(jwt)
    if(authData.role == 'ADMIN' || authData.role == 'SUPERADMIN'){
      showAdmin = true
    }
    user_tab_text = 'Account'
    user_tab_link = '/UserPage'
  }

  return(
    <div className="NavBar">
      <img src="logo_white.png" />
      <Link to="/" className = {active == 'Home' ? 'active' : ''}>Home</Link>
      <Link to="/Contact" className = {active == 'Contact' ? 'active' : ''}>Contact</Link>
      <Link to="/Data" className = {active == 'Data' ? 'active' : ''}>Data</Link>
      <Link to="/OldMaps" className = {active == 'OldMaps' ? 'active' : ''}>Old Maps</Link>
      <Link to="/ModelHex" className = {active == 'ModelHex' ? 'active' : ''}>Mapped Statistics</Link>
      <Link to="/ModelHex2" className = {active == 'ModelHex2' ? 'active' : ''}>Daily Populations</Link>
      <Link to="/ModelHex3" className = {active == 'ModelHex3' ? 'active' : ''}>Multi-Trips</Link>
      {showAdmin && (<Link to="/Admin" className = {active == 'Admin' ? 'active' : ''}>Admin</Link>)}
      <Link to={user_tab_link} className = {"user_tab " + (active == 'Login' || active == 'UserPage' ? 'active' : '')}>{user_tab_text}</Link>
    </div>
  )
}

export default NavBar