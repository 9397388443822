function Logo({color, height}: {color: string, height: number}){
  return (
    <div style={{'height': height, 'width': 'maxContent'}}>
      <svg height='100%' viewBox="0 0 106 110" style={{'float': 'left', 'marginRight': height * 0.2}} xmlns="http://www.w3.org/2000/svg">
        <path d="M83.236 54.259s3.105.675 6.344-2.7c3.24-3.375 2.97-12.014 2.565-16.874-.405-4.86-3.51-21.058-8.909-21.193-5.4-.135-9.99 16.469-9.99 21.193 0 4.725-.54 13.77 2.97 16.874 3.51 3.105 7.02 2.7 7.02 2.7z" stroke={color} stroke-width="3" fill="none"/>
        <path d="M14.684 28.789s2.086.453 4.261-1.813c2.176-2.266 1.995-8.068 1.723-11.332-.272-3.264-2.357-14.142-5.984-14.233-3.626-.09-6.708 11.06-6.708 14.233s-.363 9.247 1.994 11.332c2.357 2.085 4.714 1.813 4.714 1.813z" stroke={color} stroke-width="2.68628" fill="none"/>
        <path d="M14.391 28.746v7.56M1.77 42.77s26.998-16.334 53.726-16.334c9.912 0 18.708 2.031 18.708 2.031" stroke={color} stroke-width="3" fill="none"/>
        <path d="M14.916 68.838s-.27-11.61 8.505-26.593c8.774-14.984 25.783-15 25.783-15" stroke={color} stroke-width="3" fill="none"/>
        <path d="M43.414 33.902s2.689-2.716 4.833-4.682c1.093-1.002 4.11-2.42 5.021-2.784M32.885 51.559s.27-2.295 3.375-7.83M46.519 59.388s-1.485-9.989 3.51-19.573c4.994-9.584 14.713-12.57 14.713-12.57" stroke={color} stroke-width="3" fill="none"/>
        <path d="M1.567 75.992S29.14 58.78 53.268 58.983c24.13.203 44.817 9.855 51.431 16.064M83.371 54.881V65.41" stroke={color} stroke-width="3" fill="none"/>
        <path d="M100.515 107.984s-.675-14.308-5.94-25.243C89.31 71.807 85.97 67.926 77.701 62.358M66.242 109.422s3.765-14.054 1.74-26.608C65.957 70.26 55.038 59.678 49.1 59.004M84.871 104.475s.255-3.375-.555-8.91M82.3 84.713s-.266-1.44-1.481-4.275c-1.215-2.835-2.7-4.59-2.7-4.59M71.717 66.91s-4.243-5.073-6.557-6.764M104.577 42.23s-2.531-3.23-12.014-6.828" stroke={color} stroke-width="3" fill="none"/>
      </svg>
      <div style={{'height': '100%', 'float': 'left', 'fontFamily': 'Verdana'}}>
        <div style={{'height': '80%', 'lineHeight': '80%', 'fontWeight': 'bold', 'fontSize': height*0.9}}>
          <text style={{'verticalAlign': 'middle'}}>CliMR</text>
        </div>
        <div style={{'height': '20%', 'textAlign': 'center', 'fontSize': height*0.15}}>
          <text style={{'verticalAlign': 'middle', 'width': '100%'}}>Climate Mobility Reasearch Network</text>
        </div>
      </div>
    </div>
  )
}

export default Logo