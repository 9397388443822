import { useState } from "react";
import NavBar from "./NavBar"
import { Navigate, Link } from "react-router-dom";

function RequestAccess() {

  const [pass, setPass] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [useReason, setUseReason] = useState('')
  const [emailList, setEmailList] = useState(true)
  const [output, setOutput] = useState('')
  const [returnLink, setReturnLink] = useState(false)

  const jwt = localStorage.getItem('jwt')
  if (jwt != null){
    return (<Navigate to='/'></Navigate>)
  }

  function submit() {
    setReturnLink(false)
    let r_status: number
    if(email == ''){
      setOutput('Must provide an email')
      return
    }
    if(pass == ''){
      setOutput('Must provide a password')
      return
    }
    if(firstName == ''){
      setOutput('Must provide a first name')
      return
    }
    if(lastname == ''){
      setOutput('Must provide a last name')
      return
    }
    if(useReason == ''){
      setOutput('Must provide a reason for accessing the data')
      return
    }

    fetch('/api/auth/requestAccess', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email, 
        password: pass, 
        firstName: firstName, 
        lastName: lastname, 
        useReason: useReason, 
        emailList: emailList,
      })
    })
    .then(r => {
      r_status = r.status
      console.log(r)
      if(r_status != 201){
        r.json().then(r => {
          console.log('a')
          setOutput(r.message)
        })
      }else{
        setOutput('Request submitted')
        setEmail('')
        setPass('')
        setFirstName('')
        setLastName('')
        setUseReason('')
        setEmailList(true)
        setReturnLink(true)
      }
    })
  }

  return(
    <>
      <NavBar active=''/>
      <div className='content'>
        <h2>Request Access</h2>
        <label>Email:</label>
        <input type='email' value={email} onChange={ev => setEmail(ev.target.value)}/>
        <br />
        <label>Password:</label>
        <input type='password' value={pass} onChange={ev => setPass(ev.target.value)}/>
        <br />
        <label>First Name:</label>
        <input type='text' value={firstName} onChange={ev => setFirstName(ev.target.value)}/>
        <br />
        <label>Last Name:</label>
        <input type='text' value={lastname} onChange={ev => setLastName(ev.target.value)}/>
        <br />
        <label>Reason for accessing data:</label>
        <br />
        <textarea value={useReason} rows={4} cols={40} onChange={ev => setUseReason(ev.target.value)}/>
        <br />
        <label>Would you like to stay in touch with project development? </label>
        <input type='checkbox' checked={emailList} onChange={ev => setEmailList(ev.target.checked)}/>
        <br />
        <button onClick={submit}>Submit</button>
        <p>{output}</p>
        {returnLink && (<Link to='/'>Return to home page</Link>)}
      </div>
    </>
  )
}

export default RequestAccess