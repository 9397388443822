import NavBar from "./NavBar"

function Contact() {
  return (
    <>
      <NavBar active='Contact'/>
      <div className='content'>
        <a href='https://icer.ok.ubc.ca'><img height = '100px' src='icer-logo-home-1.jpg' /></a>
        <p>
          Institute for Community Engaged Research<br />
          Okanagan Campus<br />
          ART368 - 1147 Research Road<br />
          Kelowna, BC Canada V1V 1V7
        </p>
        <p>
          Email: jon.corbett@ubc.ca
        </p>
        <p>
          Phone: 250-807-9667
        </p>
      </div>
    </>
  )
}

export default Contact