import CategoryDataPoint from "./CategoryDataPoint"
import Pie from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';

const tab20Colors = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
]

const PieChart = ({radius, data}: {radius: number, data: CategoryDataPoint[]}) => {
  return(
    <svg width={radius * 2} height={radius * 2}>
      <Group top={radius} left={radius}>
        <Pie
          data={data}
          pieValue={d => d.value}
          outerRadius={radius}
        >
          {(pie) => {
            return pie.arcs.map((arc, index) => {
              const [centroidX, centroidY] = pie.path.centroid(arc);
              const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
              const arcPath = pie.path(arc);
              const colorIndex = index < 10 ? index : (index % 10) + Math.floor(index / 10)
              const arcFill = tab20Colors[colorIndex * 2];
              return (arcPath &&
                <g key={`arc-${arc.data.categoryTitle}-${index}`}>
                  <path d={arcPath} fill={arcFill} />
                  {hasSpaceForLabel && (
                    <text
                      x={centroidX}
                      y={centroidY}
                      dy=".33em"
                      fill='#000000'
                      fontSize={22}
                      textAnchor="middle"
                      pointerEvents="none"
                    >
                      {arc.data.categoryTitle}
                    </text>
                  )}
                </g>
              );
            });
          }}
        </Pie>
      </Group>
    </svg>
  )
}

export default PieChart