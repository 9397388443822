import NavBar from './NavBar.tsx'
import ExtrudedMap from './ExtrudedMap.tsx'
import './css/MapPage.css'
import BarPlotMap from './BarPlotMap.tsx'

function MapPage(){
  return(
    <>
      <NavBar active='Map'/>
      <div className='content'>
        <h1 style={{textAlign: 'center'}}>Bar Plot Map Example</h1>
        <div className='mapContainer'>
          <BarPlotMap/>
        </div>
        <h1 style={{textAlign: 'center'}}>Extruded Map Example</h1>
        <div className='mapContainer'>
          <ExtrudedMap/>
        </div>
      </div>
     </>
  )
}

export default MapPage