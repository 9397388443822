import NavBar from "./NavBar"
import { useNavigate } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import './css/Admin.css'
import { AuthPayload, decodeJWT } from "./Auth"
import { callAPI } from "./Api";

function Admin() {

  interface UserData {
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    role: string,
    useReason: string,
    pending: boolean,
    disabled: boolean,
  }
  
  const navigate = useNavigate()
  const [usersData, setUsersData] = useState<UserData[] | null>(null)
  const [jwt, setJwt] = useState<string | null>(null)
  const [authData, setAuthData] = useState<AuthPayload | null>(null)

  useEffect(() => {
    const jwt = localStorage.getItem('jwt')
    if (jwt == null){
      navigate('/Login')
      return
    } 
    const authData = decodeJWT(jwt)
    if(! (authData?.role == 'ADMIN' || authData?.role == 'SUPERADMIN')){
      navigate('/')
      return
    }
    setJwt(jwt)
    setAuthData(authData)
  }, [navigate])

  useMemo(() => {
    if (jwt == null) return
    callAPI('/api/auth/getUsers', 'GET', {onTimeout: () => navigate('/login')})
      .then((resp) => {return resp.json()})
      .then((r) => {console.log(r); setUsersData(r);})
  }, [jwt, navigate]);

  function setUserPending(userId: number, pending: boolean){
    callAPI('/api/auth/setUserPending', 'POST', {
      body: JSON.stringify({userId: userId, pending: pending}),
      onTimeout: () => navigate('/login')
    }).then((r) => {
      if(r.ok){
        if (usersData == null) return;
        r.json().then((updatedUser => {
          setUsersData(usersData.map((user) => user.id == updatedUser.id ? updatedUser : user))
        }))
      }
    })
  }

  function setUserDisabled(userId: number, disabled: boolean){
    callAPI('/api/auth/setUserDisabled', 'POST', {
      body: JSON.stringify({userId: userId, disabled: disabled}),
      onTimeout: () => navigate('/login')
    }).then((r) => {
      if(r.ok){
        if (usersData == null) return;
        r.json().then((updatedUser => {
          setUsersData(usersData.map((user) => user.id == updatedUser.id ? updatedUser : user))
        }))
      }
    })
  }

  function setUserRole(userId: number, role: string){
    callAPI('/api/auth/setUserRole', 'POST', { 
      body: JSON.stringify({userId: userId, role: role}),
      onTimeout: () => navigate('/login')
    }).then((r) => {
      if(r.ok){
        if (usersData == null) return;
        r.json().then((updatedUser => {
          setUsersData(usersData.map((user) => user.id == updatedUser.id ? updatedUser : user))
        }))
      }
    })
  }

  function setUserAdmin(userId: number, admin: boolean){
    callAPI('/api/auth/setUserAdmin', 'POST', {
      body: JSON.stringify({userId: userId, admin: admin}),
      onTimeout: () => navigate('/login')
    }).then((r) => {
      if(r.ok){
        if (usersData == null) return;
        r.json().then((updatedUser => {
          setUsersData(usersData.map((user) => user.id == updatedUser.id ? updatedUser : user))
        }))
      }
    })
  }

  return(
    <>
      <NavBar active='Admin'/>
      <div className="content">
        <h1>Admin dashboard</h1>
        {usersData != null && (
          <>
            <h2>Pending Accounts</h2>
            <table className='userDataTable'>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Reason for data access</th>
              </tr>
              {usersData.map((user) => {
                if (user.pending){
                  return (
                    <tr>
                      <td>
                        {user.firstName}
                      </td>
                      <td>
                        {user.lastName}
                      </td>
                      <td>
                        {user.email}
                      </td>
                      <td>
                        {user.useReason}
                      </td>
                      <td>
                        <input type='button' value='Accept' onClick={() => setUserPending(user.id, false)} />
                      </td>
                    </tr>
                  )
                }
              })}
            </table>
            <h2>Active Accounts</h2>
            <table className='userDataTable'>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Reason for data access</th>
                <th>Role</th>
              </tr>
              {usersData.map((user) => {
                if (!user.pending && !user.disabled && !['ADMIN', 'SUPERADMIN'].includes(user.role)){
                  return (
                    <tr>
                      <td>
                        {user.firstName}
                      </td>
                      <td>
                        {user.lastName}
                      </td>
                      <td>
                        {user.email}
                      </td>
                      <td>
                        {user.useReason}
                      </td>
                      <td>
                        <select value={user.role} onChange={(e) => setUserRole(user.id, e.target.value)}>
                          <option>
                            USER
                          </option>
                          <option>
                            MEMBER
                          </option>
                        </select>
                      </td>
                      <td>
                        <input type='button' value='Disable' onClick={() => setUserDisabled(user.id, true)} />
                      </td>
                      {authData?.role == 'SUPERADMIN' && (
                        <td>
                          <input type='button' value='Set Admin' onClick={() => setUserAdmin(user.id, true)} />
                        </td>
                      )}
                    </tr>
                  )
                }
              })}
            </table>
            <h2>Disabled Accounts</h2>
            <table className='userDataTable'>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Reason for data access</th>
              </tr>
              {usersData.map((user) => {
                if (!user.pending && user.disabled){
                  return (
                    <tr>
                      <td>
                        {user.firstName}
                      </td>
                      <td>
                        {user.lastName}
                      </td>
                      <td>
                        {user.email}
                      </td>
                      <td>
                        {user.useReason}
                      </td>
                      <td>
                        <input type='button' value='Reactivate' onClick={() => setUserDisabled(user.id, false)} />
                      </td>
                    </tr>
                  )
                }
              })}
            </table>
            {authData?.role == 'SUPERADMIN' && (
              <>
                <h2>Admin Accounts</h2>
                <table className='userDataTable'>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                  </tr>
                  {usersData.map((user) => {
                    if (user.role == 'ADMIN'){
                      return (
                        <tr>
                          <td>
                            {user.firstName}
                          </td>
                          <td>
                            {user.lastName}
                          </td>
                          <td>
                            {user.email}
                          </td>
                          <td>
                            <input type='button' value='Remove Admin' onClick={() => setUserAdmin(user.id, false)} />
                          </td>
                        </tr>
                      )
                    }
                  })}
                </table>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Admin