import { useMemo } from "react";
import { GeoJsonProperties } from 'geojson';
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from '@visx/scale';
import { motion } from "framer-motion";

const Graph = ({height, width, data, value, selected}: {
  height: number, 
  width: number, 
  data: GeoJsonProperties[], 
  value: string, 
  selected: string | null
}) => {
  const xMax = width;
  const yMax = height;

  const selectedIndex = useMemo(() => {
    if(selected == null){return null}
    return data.sort((a, b) => {
      return (a == null ? 0 : a[value]) - (b == null ? 0 : b[value])
    })
    .map((properties) => {return properties?.id})
    .indexOf(parseInt(selected))
  }, [data, selected, value])

  // scales, memoize for performance
  const xScale = useMemo(() => {
    data.sort((a, b) => {
      return (a == null ? 0 : a[value]) - (b == null ? 0 : b[value])
    })
    return scaleBand<string>({
      range: [0, xMax],
      domain: data.map((properties) => {return properties?.id}),
      padding: 0.5,
    })
  },[data, value, xMax]);
  
  const xScales = useMemo(() => {
    data.sort((a, b) => {
      return (a == null ? 0 : a[value]) - (b == null ? 0 : b[value])
    })
    if(selectedIndex == null){
      return []
    }
    return [
      scaleBand<string>({
        range: [0, (xMax * 0.9) * (selectedIndex/data.length)],
        domain: data.slice(0, selectedIndex).map((properties) => {return properties?.id}),
        padding: 0.5,
      }),
      scaleBand<string>({
        range: [(xMax * 0.9) * (selectedIndex/data.length) + 0.1 * xMax, xMax],
        domain: data.slice(selectedIndex + 1, data.length).map((properties) => {return properties?.id}),
        padding: 0.5,
      })
    ]
  },[data, selectedIndex, value, xMax]);

  const yScale = useMemo(() => scaleLinear<number>({
    range: [0, yMax],
    round: true,
    domain: [0, Math.max(...data.map((properties) => {return properties == null ? 0 : properties.value1}))],
  }),[data, yMax]);

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <Group transform={`matrix(1 0 0 -1 0 ${yMax})`}>
        {data.map((properties, index) => {
          const id = properties?.id;
          let barWidth = xScale.bandwidth();
          let barX = xScale(id);
          let saturation = 70;
          if(selectedIndex != null){
            if(index == selectedIndex){
              barWidth = xMax*0.1;
              barX = xScales[0].range()[1]
              saturation = 100;
            }
            else{
              const xScaleIndex = index < selectedIndex ? 0 : 1;
              barWidth = xScales[xScaleIndex].bandwidth()
              barX = xScales[xScaleIndex](id)
            }
          }
          const barHeight = yScale(properties?.[value]) ?? 0;
          const color = `hsl(${(1 - properties?.[value]) * 50}, ${saturation}%, ${70 - properties?.[value] * 20}%)`
          return (
            <motion.rect 
              initial={false}
              animate={{
                height: barHeight,
                x: barX,
                fill: color,
                width: barWidth,
              }}
              transition={{ duration: 0.5 }}
              key={`bar-${id}`}
              y={0}
              onClick={(e) => {
                if (e) console.log(`clicked: ${JSON.stringify(properties)}`);
              }}
            />
          );
        })}
      </Group>
    </svg>
  );
}

export default Graph