import { scaleLinear } from '@visx/scale';
import { Group } from "@visx/group";
import { curveMonotoneX } from "@visx/curve";
import { LinePath } from "@visx/shape";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { GridRows, GridColumns } from "@visx/grid";
import { useMemo, useState } from "react";
import { localPoint } from '@visx/event';
import { kellyColorsHex } from '../utils/ColorUtils';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';

function convertHour24ToTimeString(index: number): string {
  const time24 = index % 24;
  const amPm = time24 < 12 ? 'am' : 'pm';
  return `${time24 % 12 == 0 ? 12 : time24 % 12}${amPm}`;
}


function HourlyLineChart(
  {width, height, data, currentTimeIndex, onSelectTimeIndex, hoveredHex, setHoveredHex}: 
  {
    width: number, 
    height: number, 
    data: {hex: string, values: number[]}[], 
    currentTimeIndex: number, 
    onSelectTimeIndex: (timeIndex: number) => void,
    hoveredHex: string | null,
    setHoveredHex: (hex: string | null) => void
  }
)
{ 
  const [margin, setMargin] = useState<{top: number, right: number, bottom: number, left: number}>({top: 20, right: 20, bottom: 30, left: 50 })
  const [xMax, setXMax] = useState<number>(0)
  const [yMax, setYMax] = useState<number>(0)
  const [dataMax, setDataMax] = useState<number>(0)
  const [hoveredTimeIndex, setHoveredTimeIndex] = useState<number | null>(0)

  const {
    tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip,
  } = useTooltip<{value: number, color: string}>();

  useMemo(() => setXMax(width - margin.left - margin.right), [margin, width]);
  useMemo(() => setYMax(height - margin.top - margin.bottom), [margin, height]);
  
  useMemo(() => setMargin({top: 20, right: 20, bottom: 30, left: 20 + (10 * Math.floor(Math.log10(dataMax))) }), [dataMax])

  // scales
  const timeScale = useMemo(() => {
    return scaleLinear<number>({
      range: [0, xMax],
      domain: [0, data[0].values.length - 1],
  })}, [data, xMax]);
  
  const valueScale = useMemo(() => {
    const max = Math.max(...data.map(row => Math.max(...row.values)))
    const min = Math.min(...data.map(row => Math.min(...row.values)))
    setDataMax(max)
    const dataRange = max - min
    return scaleLinear<number>({
      range: [0, yMax],
      domain: [max + (dataRange*0.1), min - (dataRange*0.1)],
  })}, [data, yMax]);

  function onChartClick(e: React.MouseEvent<SVGElement>) {
    const point = localPoint(e)
    if(!point) return;
    const x = timeScale.invert(point.x - margin.left);
    if(x < 0 || x > data[0].values.length - 1) return
    onSelectTimeIndex(Math.round(x))
  }

  function onChartHover(e: React.MouseEvent<SVGElement>) {
    const point = localPoint(e)
    if(!point) {
      setHoveredTimeIndex(null);
      return;
    }
    const x = timeScale.invert(point.x - margin.left);
    if(x < 0 || x > data[0].values.length - 1){
      setHoveredTimeIndex(null);
      return;
    }
    setHoveredTimeIndex(Math.round(x))
  }

  return (
    <>
      <div style={{position: 'relative'}}>
        {tooltipOpen && hoveredTimeIndex != null && tooltipData && (
          <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
            <span style={{'color': tooltipData.color}}>{tooltipData.value}</span>
          </TooltipWithBounds>
        )}
      </div>
      <svg 
        width={width}
        height={height}
        onClick={onChartClick}
        onMouseMove={onChartHover}
        onMouseLeave={() => setHoveredTimeIndex(null)}
      >
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={valueScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <GridColumns
            scale={timeScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <line x1={xMax} x2={xMax} y1={0} y2={yMax} stroke="#e0e0e0"/>
          <AxisBottom
            top={yMax}
            scale={timeScale}
            tickValues={[0, 4, 8, 12, 16, 20, 24]}
            tickFormat={(index) => convertHour24ToTimeString(index.valueOf())}
          />
          <AxisLeft scale={valueScale} label='Population' labelOffset={10 * Math.floor(Math.log10(dataMax))}/>
          {data.map((row, index) => {
            const color = row.hex == 'total' 
              ? 'black' 
              : (
                data.map(row => row.hex).includes(hoveredHex ?? '') && hoveredHex != row.hex
                  ? '#DCDCDC'
                  : kellyColorsHex[index % kellyColorsHex.length]
              )
            return (
              <>
                <LinePath
                  data={[...row.values.keys()]}
                  curve={curveMonotoneX}
                  x={(d) => timeScale(d)}
                  y={(d) => valueScale(row.values[d])}
                  fill={'none'}
                  stroke={color}
                  strokeWidth={row.hex == hoveredHex ? 4 : 2}
                />
                <LinePath
                  data={[...row.values.keys()]}
                  curve={curveMonotoneX}
                  x={(d) => timeScale(d)}
                  y={(d) => valueScale(row.values[d])}
                  fill={'none'}
                  stroke={'black'}
                  strokeOpacity={0}
                  onMouseMove= {e => {
                    setHoveredHex(row.hex)
                    if(hoveredTimeIndex){
                      showTooltip({
                        tooltipLeft: timeScale(hoveredTimeIndex) + margin.left, 
                        tooltipTop: localPoint(e)?.y, 
                        tooltipData: {value: row.values[hoveredTimeIndex], color: color}
                      });
                    }
                  }}
                  onMouseLeave={() => {setHoveredHex(null); hideTooltip()}}
                  strokeWidth={8}
                />
              </>
            )
          })}
          <line 
            x1={timeScale(currentTimeIndex)}
            x2={timeScale(currentTimeIndex)}
            y1={yMax}
            y2={0}  
            stroke={'black'}
            strokeWidth={2}
            style={{'pointerEvents': 'none'}}
          />
          {hoveredTimeIndex != null && (
            <line 
            x1={timeScale(hoveredTimeIndex)}
            x2={timeScale(hoveredTimeIndex)}
            y1={yMax}
            y2={0}  
            stroke={'black'}
            strokeWidth={2}
            opacity={0.6}
            style={{'pointerEvents': 'none'}}
          />
          )}
        </Group>
      </svg>
    </>
  )
}

export default HourlyLineChart