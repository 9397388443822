import MapboxMap, { FillLayer, FullscreenControl, Layer, LineLayer, LngLatBoundsLike, MapLayerMouseEvent, MapRef, NavigationControl, SkyLayer, Source } from 'react-map-gl';
import NavBar from "./NavBar"
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FeatureCollection, Feature } from 'geojson';
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import './css/Map.css'
import './css/Census.css'
import PercentageBar from './percentageBar';
import { schemeSet1 } from 'd3-scale-chromatic';

function Census() {

  const mapRef = useRef<MapRef>(null);
  type Location = {
    lng: number
    lat: number
    zoom: number
    bounds: LngLatBoundsLike
  }

  type CommuteStatCategory = {
    title: string
    name: string
  }

  type CommuteStat = {
    title: string
    totalName: string
    categories: CommuteStatCategory[]
  }

  const commuteStats: CommuteStat[] = [
    {
      title: 'Commute Destination',
      totalName: 'commute_dest_total',
      categories: [
        {title: 'Within Census Sub-division (CSD)', name: 'commute_dest_within_csd'},
        {title: 'Within Census Division (CD) but Outside CSD', name: 'commute_dest_diff_csd'},
        {title: 'Within Province but outside CD', name: 'commute_dest_diff_cd'},
        {title: 'Outside of Province', name: 'commute_dest_diff_prov'},
      ]
    },
    {
      title: 'Method of Commute',
      totalName: 'commute_mode_total',
      categories: [
        {title: 'Drive', name: 'commute_mode_car_truck_van'},
        {title: 'Transit', name: 'commute_mode_transit'},
        {title: 'Cycle', name: 'commute_mode_cycle'},
        {title: 'Walk', name: 'commute_mode_walk'},
        {title: 'Other', name: 'commute_mode_other'},
      ]
    },
    {
      title: 'Commute Length',
      totalName: 'commute_mode_total',
      categories: [
        {title: 'Under 15min', name: 'commute_duration_under_15_min'},
        {title: '15-29min', name: 'commute_duration_15_to_29_min'},
        {title: '30-45min', name: 'commute_duration_30_to_44_min'},
        {title: '45-59min', name: 'commute_duration_45_to_59_min'},
        {title: '60min or Over', name: 'commute_duration_60_or_over_min'},
      ]
    },
    {
      title: 'Commute Leave Time',
      totalName: 'commute_leave_time_total',
      categories: [
        {title: '5am-6am', name: 'commute_leave_time_5am_to_6am'},
        {title: '6am-7am', name: 'commute_leave_time_6am_to_7am'},
        {title: '7am-8am', name: 'commute_leave_time_7am_to_8am'},
        {title: '8am-9am', name: 'commute_leave_time_8am_to_9am'},
        {title: '9am-12pm', name: 'commute_leave_time_9am_to_12pm'},
        {title: '12pm-5am', name: 'commute_leave_time_12pm_to_5am'},
      ]
    }
  ]

  const kelowna: Location = useMemo(() => { 
    return{
      lng: -119.5333,
      lat: 49.9313,
      zoom: 9.08,
      bounds: [[-120.75, 49.5], [-118.25, 50.4]],
    }
  }, [])
  
  const vancouver: Location = useMemo(() => {
    return {
      lng: -122.9307,
      lat: 49.1910,
      zoom: 9.82,
      bounds: [[-124, 48.5], [-121.75, 49.7]],
    }
  }, [])

  const [currentLocation, setCurrentLocation] = useState<Location>(kelowna)
  const [lng, setLng] = useState(currentLocation.lng);
  const [lat, setLat] = useState(currentLocation.lat);
  const [zoom, setZoom] = useState(currentLocation.zoom);
  const [maxBounds, setMaxBounds] = useState<LngLatBoundsLike | undefined>(currentLocation.bounds);
  const [mapFlying, setMapFlying] = useState(false)
  const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>([])
  const [currentCommuteStat, setCurrentCommuteStat] = useState(commuteStats[0])
  const [currentCommuteStatCategory, setcurrentCommuteStatCategory] = useState(commuteStats[0].categories[0])

  const [censusData, setCensusData] = useState<FeatureCollection | null>(null)

  useEffect(() => {
    fetch('api/geoDatabase/getCensusCommuteData')
      .then(resp => resp.json())
      .then(json => {setCensusData(json); return json})
      .catch(err => console.error('Could not load data', err));
  }, []);

  useEffect(() => {
    console.log(censusData)
  }, [censusData])

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': zoom
    }
  };

  const outlineLayer: LineLayer = {
    id: 'outlineLayer',
    type: 'line',
    paint: {
      'line-color': '#000000',
      'line-width': [
        'interpolate', 
        ['linear'], 
        ["zoom"],
        9, 0.3, 
        12, 2,
      ],
    }
  }

  const selectOutlineLayer: LineLayer = useMemo(() =>{
    const layer: LineLayer = {
      id: 'selectOutlineLayer',
      type: 'line',
      paint: {
        'line-color': schemeSet1[currentCommuteStat.categories.indexOf(currentCommuteStatCategory)],
        'line-width': [
          'interpolate', 
          ['linear'], 
          ["zoom"],
          9, 0.6, 
          12, 4,
        ],
      },
      filter: ['in', ['id'], ['literal', selectedFeatures.map(f=>f.id)]]
    }
    return layer
  }, [currentCommuteStat.categories, currentCommuteStatCategory, selectedFeatures])

  const censusDataLayer: FillLayer = useMemo(() =>{
    const layer: FillLayer = {
      id: 'censusDataLayer',
      type: 'fill',
      paint: {
        'fill-color': ['case', 
          ['to-boolean', ['get', currentCommuteStat.totalName]],
          ['interpolate-hcl', 
            ['linear'],
            ['/', ['get', currentCommuteStatCategory.name], ['get', currentCommuteStat.totalName]],
            0, ['rgb', 255, 255, 255],
            1, schemeSet1[currentCommuteStat.categories.indexOf(currentCommuteStatCategory)]
          ],
          ['rgb', 160, 160, 160]
        ],
        'fill-opacity': 0.8,
      }
    }
    return layer
  }, [currentCommuteStat, currentCommuteStatCategory])

  function changeCurrentLocation(location: Location){
    if(!mapFlying){
      setSelectedFeatures([])
      setCurrentLocation(location)
    }
  }

  useMemo(() => {
    if(mapRef.current == undefined){return}
    setMaxBounds(undefined)
    setMapFlying(true)
    mapRef.current?.flyTo({
      center: {
        lng: currentLocation.lng, 
        lat: currentLocation.lat
      }, 
      zoom: currentLocation.zoom,
      pitch: 0,
      bearing: 0
    })
  }, [currentLocation])

  useEffect(() => {
    console.log(currentCommuteStat);
  }, [currentCommuteStat])

  function onMapMoveEnd(){
    if(mapFlying){
      setMaxBounds(currentLocation.bounds)
      setMapFlying(false)
      // mapRef.current?.easeTo({
      //   pitch: 0,
      //   bearing: 0
      // })
    }
  }

  function onMapClick(e: MapLayerMouseEvent){
    console.log(e.features)
    if (!e.features || e.features?.length == 0 || e.features[0].properties?.[commuteStats[0].totalName] == null){
      return
    }
    const clickedFeature = e.features[0]
    if (selectedFeatures.map(f=>f.id).includes(clickedFeature.id)){
      setSelectedFeatures(selectedFeatures.filter(f => f.id != clickedFeature.id))
    }
    else{
      setSelectedFeatures(selectedFeatures.concat([clickedFeature]))
    }
  }

  const onMapLoad = useCallback(() => {
    mapRef.current?.getMap().addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
      'maxzoom': 14
    });
    mapRef.current?.getMap().setTerrain({
      'source': 'mapbox-dem',
      'exaggeration': 1.5
    });
    mapRef.current?.on('move', () => {
      if(mapRef.current){
        setLng(mapRef.current.getCenter().lng);
        setLat(mapRef.current.getCenter().lat);
        setZoom(mapRef.current.getZoom());
      }
    });
  }, []);

  return (
    <>
      <NavBar active='Census'/>
      <div className='content'>
        <div className='mainMap'>
          <div className='map-container' style={{pointerEvents: mapFlying ? 'none' : 'auto'}}>
            <MapboxMap
              id='map'
              ref={mapRef}
              interactive={true}
              interactiveLayerIds={['censusDataLayer']}
              onLoad={onMapLoad}
              onClick={onMapClick}
              onMoveEnd={onMapMoveEnd}
              mapboxAccessToken="pk.eyJ1Ijoic3R1bWNnIiwiYSI6ImNrcDRtdHE1ZjBiYTkyeHQ4NG5sc3VpM2MifQ.N_cSsEXlTBk7q92Xdpfbug"
              initialViewState={{
                longitude: lng,
                latitude: lat,
                zoom: zoom,
              }}
              maxBounds={maxBounds}
              mapStyle="mapbox://styles/mapbox/streets-v9"
            >
              <Layer {...skyLayer} />
              <NavigationControl position='bottom-left' visualizePitch={true}/>
              <FullscreenControl position='bottom-left'/>
              <div className='map-controls'>
                <div className="map-button disabledControl" onClick={() => changeCurrentLocation(kelowna)}>Kelowna</div>
                <div className="map-button" onClick={() => changeCurrentLocation(vancouver)}>Vancouver</div>
                <div className="map-button" style={{float: 'right'}} onClick={() => {
                  setSelectedFeatures(censusData == null ? [] : censusData.features.filter((f)=>{
                    return f.properties?.[commuteStats[0].totalName] != null && f.properties?.['region'] == (currentLocation == kelowna ? 'ok ' : 'va ')
                  }))
                }}>Select All</div>
                <div className="map-button" style={{float: 'right'}} onClick={() => setSelectedFeatures([])}>Deselect All</div>
                <br/>
                {selectedFeatures.length > 0 && (
                  <div className='right-side-bar' style={{width: '20%'}}>
                    <h2>Selection</h2>
                    {commuteStats.map((stat) => {
                      return (
                        <>
                          <h3>{stat.title}:</h3>
                          <ParentSize>
                            {({width: width}) => (
                              <PercentageBar 
                                height={30} 
                                width={width}
                                data={stat.categories.map((category) => {
                                  return {
                                    categoryName: category.name, 
                                    categoryTitle: category.title, 
                                    value: selectedFeatures.reduce((sum, feature) => sum + parseInt(feature.properties?.[category.name]), 0)
                                  }
                                })}
                              />
                            )}
                          </ParentSize>
                        </>
                      )
                    })}
                  </div>
                )}
                <div style={{display: 'inline-block', verticalAlign: 'top', height: '100%'}}>
                  {commuteStats.map((stat) => {
                    return (
                      <>
                        <div 
                          className={`map-button ${stat.title == currentCommuteStat.title ? 'selected' : ''}`}
                          onClick={() => {
                            setCurrentCommuteStat(stat);
                            setcurrentCommuteStatCategory(stat.categories[0])
                          }}
                        >
                          {stat.title}
                        </div>
                        <br/>
                      </>
                    )
                  })}
                </div>
                <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                  {currentCommuteStat.categories.map((category) => {
                    return (
                      <>
                        <div 
                          className={`map-button ${category == currentCommuteStatCategory ? 'selected' : ''}`}
                          onClick={() => setcurrentCommuteStatCategory(category)}
                        >
                          {category.title}
                        </div>
                        <br/>
                      </>
                    )
                  })}
                </div>
              </div>
              {
                censusData != null && censusDataLayer != null && (
                  <Source type="geojson" data={censusData}>
                    <Layer {...censusDataLayer}/>
                    <Layer {...outlineLayer}/>
                    <Layer {...selectOutlineLayer}/>
                  </Source>
                )
              }
            </MapboxMap>
          </div>
        </div>
      </div>
    </>
  )
}

export default Census