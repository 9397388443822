
export async function callAPI(url: string, method: string, options?: {body?: string, onTimeout?: () => void}): Promise<Response>{
const jwt = localStorage.getItem('jwt')
  let resp = await fetch(url, {
    method: method,
    headers: new Headers({
      Authorization: 'Bearer ' + jwt,
      'content-type': 'application/json'
    }),
    body: options?.body
  })
  if (resp.status == 401){
    const refreshResp = await fetch('/api/auth/refreshAuth', {
      method: 'POST',
    })
    if (refreshResp.status == 201){
      const newJwt = (await refreshResp.json()).jwt
      localStorage.setItem('jwt', newJwt)
      resp = await fetch(url, {
        method: method,
        headers: new Headers({
          Authorization: 'Bearer ' + newJwt,
          'content-type': 'application/json'
        }),
        body: options?.body
      })
    }
    else {
      localStorage.removeItem('jwt')
      if(options?.onTimeout){
        options.onTimeout()
      }
    }
  }
  console.log(resp)
  return resp
}